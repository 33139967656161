import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import Select from '@mui/material/Select';
import InputLabel from './InputLabel';
import { BASE_URL } from '../config';

export default function PostCatgDropdown({ val, cb }) {
  const [data, setData] = useState([{ name: 'Smartphone' }]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/category`)
      .then((res) => {
        if (res.data.success) setData(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [val]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <InputLabel label="Post Category" />
      <Select value={val} onChange={cb} displayEmpty size="small">
        {data.map((val) => (
          <MenuItem value={val.name}>{val.name}</MenuItem>
        ))}
      </Select>
    </div>
  );
}
