import React, { useState } from 'react';
import InputLabel from '../InputLabel';
import Button from '@mui/material/Button';
import axios from 'axios';
import { TextArea } from './components';
import {  SpaceBetween } from '../share';
import IconButton from '@mui/material/IconButton';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import placeholder from '../../assets/placeholder.png';
import { BlogMedia } from '../ProfileViewComponents';
import styled from 'styled-components';
import { CDN_URL } from '../../config';
import QuillEditor from '../quill/BlogEditor';

const SectionDiv = styled.div`
  margin-top: 8px;
`;

export default function ({ val, onChange }) {
  const [deleteHover, setDeleteHover] = useState(false);
  const addField = () => {
    onChange(val.sections.length, {
      delta: '',
      html: '',
      image: '',
      imageName: '',
    });
  };

  const changeFieldValue = (index, html, image, imageName) => {
    onChange(index, {
      html: html,
      image: image,
      imageName: imageName,
    });
  };
  const removeSection = (index) => {
    onChange(index, null);
  };
  const selectPhoto = (event, pos) => {
    if (!event.target.files[0]) return;
    const position = pos;
    let formData = new FormData();
    formData.append('file', event.target.files[0]);
    axios
      .post(`${CDN_URL}/api/blog/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          onChange(position, {
            delta: val.sections[position].delta,
            html: val.sections[position].html,
            image: res.data.filename,
          });
        }
      });
  };
  return (
    <div>
      {val.sections?.map((data, pos) => {
        return (
          <SectionDiv
            style={
              deleteHover
                ? { border: '1px dashed #FF9494' }
                : { border: '1px dashed transparent' }
            }
          >
            <SpaceBetween>
              <div />
              <IconButton
                aria-label="delete"
                color="error"
                onMouseOver={() => setDeleteHover(true)}
                onMouseLeave={() => setDeleteHover(false)}
                onClick={() => {
                  setDeleteHover(false);
                  removeSection(pos);
                }}
              >
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </SpaceBetween>
            <div style={{ margin: '5px 0 0px', backgroundColor: 'white' }}>
              <BlogMedia>
                {data.image ? (
                  <img
                    className="previous-photo"
                    src={`${CDN_URL}/cdn/photo/${data.image}`}
                  ></img>
                ) : (
                  <img className="placeholder" src={placeholder}></img>
                )}
                <p>Upload</p>
                <input
                  className="update-photo"
                  type="file"
                  accept="image/*"
                  onChange={(event) => selectPhoto(event, pos)}
                />
              </BlogMedia>
            </div>
            <InputLabel label="Image name" />
            <TextArea
              id="imageName"
              label="imageName"
              placeholder="Image name"
              rows="1"
              onChange={(e) => {
                changeFieldValue(
                  pos,
                  val.sections[pos].html,
                  val.sections[pos].image,
                  e.currentTarget.value
                );
              }}
              value={data.imageName}
            />
            <div style={{ maxWidth: '100%', backgroundColor: 'white' }}>
              <QuillEditor
                onEditorChange={(html, text) => {
                  changeFieldValue(
                    pos,
                    html,
                    val.sections[pos].image,
                    val.sections[pos].imageName
                  );
                }}
                preState={data.html ? data.html : ''}
              />
            </div>
          </SectionDiv>
        );
      })}
      <br />
      {/* <CenterdDiv> */}
      <Button
        variant="outlined"
        disableElevation
        size="small"
        onClick={addField}
      >
        + Add Section
      </Button>
      {/* </CenterdDiv> */}
    </div>
  );
}
