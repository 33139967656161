import styled from "styled-components";

export const NavBar = styled.div`
  display: flex;
  height: 58px;
  background-color: #1a2228;
`;

export const NavBarMobile = styled.div`
  display: flex;
  margin: 20px 20px 0;
  height: 50px;
`;
export const NavSectionMobile = styled.div`
  transition: 0.3s;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: var(--layout);
`;
export const ProfileCircleNavMobile = styled.div`
  position: relative;
  border-radius: 50%;
  margin-right: 12px;
  background-color: #54ca95;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  object-fit: cover;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    cursor: pointer;
  }
`;
export const NavSection = styled.div`
  transition: 0.3s;
  height: 100%;
  flex: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: var(--layout);
`;

export const Avatar = styled.div`
  position: relative;
  border-radius: 50%;
  height: 2.2rem;
  margin: 0.5rem;
  width: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: lightgrey;
  }
`;

export const ProfileCircle = styled.div`
  position: relative;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  object-fit: cover;
  border: 2px solid lightgray;
  overflow: hidden;
  :hover {
    background-color: lightgrey;
  }
  :active {
    border: 2.5px solid #0091f7;
  }
  :focus {
    border: 2.5px solid blue;
  }
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const ProfileCircleNav = styled.div`
  position: relative;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  object-fit: cover;
  border: 1px solid lightgray;
  overflow: hidden;
  :hover {
    background-color: lightgrey;
  }
  :active {
    border: 2px solid #0091f7;
  }
  :focus {
    border: 2px solid blue;
  }
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const GradientBorder = styled.div`
  position: relative;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  padding: 3px;
  background: linear-gradient(to right, red, purple);
  :active {
    border: 0.5px solid purple;
  }
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 2.2px solid white;
    cursor: pointer;
  }
`;

export const Badge = styled.div`
  position: absolute;
  background-color: red;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.5px;
  font-size: 12px;
  right: 1px;
`;
