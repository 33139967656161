import styled from 'styled-components';

export const GridView = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 26px;
  grid-row-gap: 16px;
  padding: 26px;
  justify-items: center;
`;

export const TabItem = styled.a`
  display: block;
  padding: 5px 0;
  margin: 0 26px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #000000aa;
  border-top: 1px solid #ececec;
  cursor: pointer;
  :active {
    color: grey;
  }
`;
export const BlogMedia = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 600px;
  border: 2px solid lightgray;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  :hover {
    background-color: lightgrey;
    .update-photo {
      z-index: 2;
      display: block;
      justify-content: center;
      align-items: center;
    }
    p {
      z-index: 3;
      diaplay: block;
    }
  }

  :active {
    border: 2px solid lightblue;
  }

  :focus {
    border: 2px solid blue;
  }

  img {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  p {
    position: absolute;
    display: none;
  }

  .update-photo {
    cursor: pointer;
    margin-top: -40px;
    position: absolute;
    display: none;
    height: 160px;
    width: 100%;
    color: lightgrey;
    background-color: #00000042;
  }
  .placeholder {
    object-fit: cover;
  }
`;
export const TabItemSelected = styled.a`
  display: block;
  padding: 5px 0;
  margin: 0 26px;
  font-size: 0.875rem;
  font-weight: 600;
  color: black;
  border-top: 1px solid black;
  cursor: pointer;
  :active {
    color: grey;
  }
`;

export const ProfileCircle = styled.div`
  position: relative;
  border-radius: 50%;
  margin: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
  object-fit: cover;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    cursor: pointer;
  }
`;
