import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import NormalizeView from "../NormalizeView";
import AddHeader from "../AddHeader";
import SubmitLoading from "../SubmitLoading";
import { BASE_URL } from '../../config';
import { ReadContainer, ReadContainer600 } from "../share";

export default function () {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetch = () => {
    axios.get(`${BASE_URL}/api/comparisons`).then((res) => {
      if (res.data.success) {
        setData(res.data.data);
      } else {
        alert("Something wrong try again later..");
      }
      setLoading(false);
    }).catch((e)=>{
      setLoading(false);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    axios.post(`${BASE_URL}/api/comparisons/delete/${id}`).then((res) => {
      if (res.data.success) {
        fetch();
      } else {
        alert("Something wrong try again later..");
        setLoading(false);
      }
    }).catch((err) => {
      alert('Unable to delete this comparison.');
      console.log(err);
      setLoading(false);
    });;
  };

  return (
    <ReadContainer600>
      <AddHeader title="Comparisons" />
      <SubmitLoading open={loading} />
      <div>
        {data.map((val, index) => (
          <NormalizeView
            key={`item-${index}`}
            index={index}
            title={val.title}
            text={val.desc}
            onEdit={() => {
              navigate(`edit/${val._id}`);
            }}
            onDelete={() => {
              handleDelete(val._id);
            }}
          />
        ))}
      </div>
    </ReadContainer600>
  );
}
