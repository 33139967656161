import React, { useState, useEffect } from 'react';
import InputHeading from '../components/InputHeading';
import { ReadContainer600 } from '../components/share';

// import Quill from 'quill';

import { useQuill } from 'react-quilljs';

import 'quill/dist/quill.snow.css';

import '../components/upload/UploadProduct.css';

// import ImageResize from 'quill-image-resize-module';

// import Quill from 'quill'
// import ReactQuill,{Quill} from 'react-quill';
// import MarkdownShortcuts from 'markdownShortCuts';
// Quill.register('modules/markdownShortcuts',MarkdownShortcuts)

// const Link = Quill.import('formats/link');

// class MyLink extends Link {
//   static create(value) {
//     console.log('----------------------create1------------------------')
//     let node = Link.create(value);
//     value = Link.sanitize(value);
//     node.setAttribute('href', value);
//     if (value.startsWith('https://quilljs.com')) {
//       node.removeAttribute('target');
//     } else {
//       node.setAttribute('target', '_blank');
//     }
//     return node;
//   }

//   format(name, value) {
//     super.format(name, value);
//     console.log('----------------------formats1------------------------')
//     if (name !== this.statics.blotName || !value) {
//       return;
//     }

//     if (value.startsWith('https://quilljs.com')) {
//       this.domNode.removeAttribute('target');
//     } else {
//       this.domNode.setAttribute('target', '_blank');
//     }
//   }
// }
// Quill.register('formats/link', MyLink);
// const Inline = Quill.import('blots/inline');

// class CustomLink extends Inline {
//   static create(options) {
//     console.log('----------------------create2------------------------')
//     const node = super.create();
//     node.setAttribute('href', options.url);

//     if (options.target) {
//       node.setAttribute('target', '_blank');
//     }
//     if (options.follow === 'nofollow') {
//       node.setAttribute('rel', 'nofollow');
//     }

//     return node;
//   }

//   static formats(node) {
//     console.log('----------------------formats2------------------------')
//     let values = {
//       url: node.getAttribute('href'),
//     };
//     if (node.getAttribute('target') == '_blank') {
//       values.target = true;
//     }
//     if (node.getAttribute('rel') == 'nofollow') {
//       values.follow = 'nofollow';
//     }
//     return values;
//   }
// }
// Quill.register('blots/inline', CustomLink);

// export const module = {
//   modules: {
//     toolbar: [
//       [{ header: [1, 2, false] }],
//       ['bold', 'italic', 'underline'],
//       ['image', 'code-block'],
//     ],
//   },
//   placeholder: 'Compose an epic...',
//   theme: 'snow', // or 'bubble'
// };

export default function () {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: {
        containers: '#toolbar',
        handlers: {
          link: (link) => {
            linkHandler(link);
          },
        },
      },
    },
  });

  const [html, setHtml] = useState('');

  function linkHandler(val) {
    console.log(val);
    if (quill) {
      const range = quill.getSelection();
      quill.insertEmbed(range.index, 'link', `http://localhost:9000${val}`);
    } else {
      console.log('quill null');
    }
  };

  useEffect(() => {
    if (quill) {
      // quill.register('modules/ImageResize', ImageResize);
      // quill.register('formats/link',Link);
      quill.insertText(0, 'Hello', 'link', 'https://world.com');
    }
  }, [quill]);
  const submitPost = (e) => {
    e.preventDefault();
    setHtml(quill.root.innerHTML);
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Add Section"
        btnTxt="Publish"
        submit={submitPost}
        back="/section"
      />

      <div style={{ width: '100%', backgroundColor: 'white' }}>
        <div ref={quillRef} />
      </div>
      <br />
      <textarea value={html} onChange={(e) => setHtml(e.target.value)} />

      <br />

      <div dangerouslySetInnerHTML={{ __html: html }} />
      <br />
    </ReadContainer600>
  );
}
// https://github.com/quilljs/quill/issues/1139
//-------------------------------------------------- BEST WAY
// const Link = Quill.import('formats/link')

// class linkType extends Link {
//   static create (value) {
//     let node = super.create(value)
//     value = this.sanitize(value)
//     if (validations.isNumeric(value)) {
//       node.setAttribute('href', 'tel:' + value)
//       node.className = 'link--tel'
//     }
//     if (validations.isEmail(value)) {
//       node.setAttribute('href', 'mailto:' + value)
//       node.className = 'link--mail'
//     }
//     if (value.startsWith('https://') || value.startsWith('http://')) {
//       node.className = 'link--external'
//     } else {
//       node.removeAttribute('target')
//     }
//     return node
//   }
// }
// var Link = Quill.import('formats/link');

// class MyLink extends Link {
//   static create(value) {
//     let node = Link.create(value);
//     value = Link.sanitize(value);
//     node.setAttribute('href', value);
//     if (value.startsWith('https://quilljs.com')) {
//       node.removeAttribute('target');
//     } else {
//       node.setAttribute('target', '_blank');
//     }
//     return node;
//   }

//   format(name, value) {
//     super.format(name, value);

//     if (name !== this.statics.blotName || !value) {
//       return;
//     }

//     if (value.startsWith('https://quilljs.com')) {
//       this.domNode.removeAttribute('target');
//     } else {
//       this.domNode.setAttribute('target', '_blank');
//     }
//   }
// }

// Quill.register(MyLink);

// var quill = new Quill('#editor-container', {
//   modules: {
//     toolbar: [
//       [{ header: [1, 2, false] }],
//       ['bold', 'italic', 'underline'],
//       ['link'],
//     ],
//   },
//   placeholder: 'Compose an epic...',
//   theme: 'snow', // or 'bubble'
// });

//------------------------------------------------------------
/** Creating a custom link blot */
// const Inline = Quill.import('blots/inline');

// class CustomLink extends Inline {
//   static create(options) {
//     const node = super.create();
//     node.setAttribute('href', options.url);

//     if (options.target) {
//       node.setAttribute('target', '_blank');
//     }
//     if (options.follow === 'nofollow') {
//       node.setAttribute('rel', 'nofollow');
//     }

//     return node;
//   }

//   static formats(node) {
//     let values = {
//       url: node.getAttribute('href'),
//     };
//     if (node.getAttribute('target') == '_blank') {
//       values.target = true;
//     }
//     if (node.getAttribute('rel') == 'nofollow') {
//       values.follow = 'nofollow';
//     }
//     return values;
//   }
// }

// CustomLink.blotName = 'custom_link';
// CustomLink.tagName = 'A';

// Quill.register({ 'formats/custom_link': CustomLink });

// var quill = new Quill('#editor-container', {
//   theme: 'bubble',
//   modules: { toolbar: ['bold', 'link'] },
// });

// quill.getModule('toolbar').addHandler('link', function (value) {
//   if (value) {
//     this.quill.format('custom_link', {
//       target: true,
//       url: 'https://foobar.com',
//       follow: 'nofollow',
//     });
//   } else {
//     this.quill.format('custom_link', false);
//   }
// });

// quill.on('text-change', () => {
//   console.log(quill.root.innerHTML);
// });
