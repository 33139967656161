import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import InputHeading from '../InputHeading';
import InputLabel from '../InputLabel';
import { BASE_URL } from '../../config';
import useAuth from '../../contexts/AuthContext';
import { TextArea } from './components';
import './UploadProduct.css';
import { ReadContainer600 } from '../share';

export default function({ edit, handleClose }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState({
    name: '',
    metaDesc: '',
  });
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [loading, setLoading] = useState(false);

  const submitPost = (e) => {
    e.preventDefault();

    // if (currentUser && !currentUser.isAuth) {
    //   return alert('Please log in first.');
    // }

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });
    axios
      .post(`${BASE_URL}/api/category/add`, {
        ...data,
        id: data.name
          .trim()
          .toLowerCase()
          .replace(' ', '-'),
      })
      .then((response) => {
        if (response.data.success) {
          // alert("Uploaded successfully!");
          navigate('/category');
        } else {
          alert('Failed while uploading your post. Please try again later.');
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        alert(
          response.err == undefined ? 'Internal Server Error' : response.err
        );
        setLoading(false);
      });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Add Category"
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/category"
      />

      <div>
        <div style={{ marginTop: '6px' }}>
          <InputLabel label="Brand" />
          <TextArea
            id="category"
            label="category"
            placeholder="category.."
            rows="1"
            style={{ maxWidth: '300px' }}
            onChange={(e) => setData({ ...data, name: e.currentTarget.value })}
            value={data.name}
          />
          <br />
          <InputLabel label="Meta description" />
          <textarea
            id="metaDesc"
            label="metaDesc"
            placeholder="Within 160-300 char"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              boxSizing: 'border-box',
              fontSize: '1rem',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            rows="3"
            onChange={(e) => {
              setData({ ...data, metaDesc: e.currentTarget.value });
            }}
            value={data.metaDesc}
          />
        </div>
      </div>

      <br />
    </ReadContainer600>
  );
}
