import React from 'react';
import axios from 'axios';
import SearchField from './upload/SearchField';
import { BASE_URL } from '../config';

export default function SearchPost({ cb }) {
  const searchByText = (text) => {
    if (!text) return cb([]);
    axios
      .get(`${BASE_URL}/api/posts?search=${text}`)
      .then((res) => {
        cb(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return <SearchField submit={searchByText} />;
}
