import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import BrandList from "../BrandList";
import AddHeader from "../AddHeader";
import SubmitLoading from "../SubmitLoading";
import { CDN_URL, BASE_URL } from "../../config";
import { ReadContainer, ReadContainer600 } from "../share";

export const groupItem = (array) => {
  let resultObj = {};

  for (let i = 0; i < array.length; i++) {
    let currentWord = array[i].name;
    let firstChar = currentWord[0].toLowerCase();
    let innerArr = [];
    if (resultObj[firstChar] === undefined) {
      innerArr.push(array[i]);
      resultObj[firstChar] = innerArr;
    } else {
      resultObj[firstChar].push(array[i]);
    }
  }
  return resultObj;
};

export default function () {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataGroup, setGroup] = useState([]);
  const navigate = useNavigate();

  const fetch = () => {
    axios
      .get(`${BASE_URL}/api/brand`)
      .then((res) => {
        if (res.data.success) {
          setGroup(groupItem(res.data.data));
          setData(res.data.data);
        } else {
          alert("Something wrong try again later..");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleEdit = (id) => {
    navigate(`edit/${id}`);
  };
  const handleDelete = (id) => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/api/brand/delete/${id}`)
      .then((res) => {
        if (res.data.success) {
          fetch();
        } else {
          alert("Something wrong try again later..");
          setLoading(false);
        }
      })
      .catch((err) => {
        alert("Unable to delete this brand.");
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <ReadContainer600>
      <AddHeader title="Brand" />
      <hr />
      <SubmitLoading open={loading} />
      <div>
        {data && (
          <BrandList
            data={data}
            dataGroup={dataGroup}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        )}
      </div>
    </ReadContainer600>
  );
}
