import React, { useState } from 'react';
import { InputWidth } from './upload/components';
import InputLabel from './InputLabel';
import AddBtn from './AddBtn';
import { NUMBER_REGX } from '../utils';

export default function RangeInput({ label, cb, suffix, join, float }) {
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');

  function isNumber(charCode) {
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  return (
    <div>
      <InputLabel label={label ? label : 'Add'} />
      <div>
        <InputWidth
          rows={1}
          type="number"
          step="any"
          placeholder="min"
          value={min}
          onChange={(e) => {
            if (float) {
              // parseFloat(el.value)
              setMin(e.target.value);
            } else {
              if (e.target.value === '' || NUMBER_REGX.test(e.target.value)) {
                setMin(e.target.value);
              }
            }
            // if (e.target.value === '' || NUMBER_REGX.test(e.target.value)) {
            //   setMin(e.target.value);
            // }
          }}
        />
        <InputWidth
          rows={1}
          type="number"
          step="any"
          placeholder="max"
          value={max}
          onChange={(e) => {
            if (float) {
              // parseFloat(el.value)
              setMax(e.target.value);
            } else {
              if (e.target.value === '' || NUMBER_REGX.test(e.target.value)) {
                setMax(e.target.value);
              }
            }
          }}
        />
      </div>
      <AddBtn
        cb={() => {
          if (cb) {
            cb({
              name: `${min} ${join ? join : '-'} ${max}${suffix ? suffix : ''}`,
              range: { min: min, max: max },
            });
          }
          setMin('');
          setMax('');
        }}
      />
    </div>
  );
}
