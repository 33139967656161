import React, { useState } from 'react';
import { TextAreaWidth } from './upload/components';
import InputLabel from './InputLabel';
import AddBtn from './AddBtn';
import { NUMBER_REGX } from '../utils';

export default function SingleInput({ label, cb, suffix, onlyNumber, float }) {
  const [value, setValue] = useState('');
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <InputLabel label={label} />
      <TextAreaWidth
        rows={1}
        placeholder="value"
        value={value}
        onChange={(e) => {
          if (onlyNumber) {
            if (float) {
              setValue(e.target.value);
            } else {
              if (e.target.value === '' || NUMBER_REGX.test(e.target.value)) {
                setValue(e.target.value);
              }
            }
          } else setValue(e.target.value);
        }}
      />
      <div>
        <AddBtn
          cb={() => {
            if (cb) cb({ name: `${value.trim()}${suffix ? suffix : ''}` });
            setValue('');
          }}
        />
      </div>
    </div>
  );
}
