import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function ({ val, onChange }) {
  const [value, setValue] = React.useState('all-brands');

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <Box style={{ width: '180px'}}>
      <FormControl fullWidth size="small">
        <Select value={value} onChange={handleChange} displayEmpty>
          <MenuItem value="all-brands">All Brand</MenuItem>
          {val &&
            val.map((d) => <MenuItem value={d.id}>{d.name}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}
