import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import useAuth from '../../contexts/AuthContext.js';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useLocation } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import CompareIcon from '@mui/icons-material/Compare';
import WebIcon from '@mui/icons-material/Web';
import DiamondIcon from '@mui/icons-material/Diamond';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import CategoryIcon from '@mui/icons-material/Category';
import ReviewsIcon from '@mui/icons-material/Reviews';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import LogoutIcon from '@mui/icons-material/Logout';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import PushPinIcon from '@mui/icons-material/PushPin';
import ShareIcon from '@mui/icons-material/Share';
import ArticleIcon from '@mui/icons-material/Article';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  SideBarItemText,
  ProfileCircle,
  TabItemText,
  SideBar,
  TabItem,
  SideBarItemSelected,
  SideBarItem,
  SideBarTabItem,
  SideBarTabItemSelected,
  SideBarItemSelectedText,
  TabSideBar,
} from './SidebarComponents.js';
import './Sidebar.css';

export const navs = [
  {
    name: 'Dashboard',
    path: 'dashboard',
    redirect: '/dashboard',
    icon: <DashboardIcon />,
  },
  {
    name: 'Add Link',
    path: 'link',
    redirect: '/link',
    icon: <PushPinIcon />,
  },
  {
    name: 'Post',
    path: 'post',
    redirect: '/post',
    icon: <WebAssetIcon />,
  },
  {
    name: 'Reveiw',
    path: 'review',
    redirect: '/review',
    icon: <ReviewsIcon />,
  },
  {
    name: 'Blog',
    path: 'blog',
    redirect: '/blog',
    icon: <RssFeedIcon />,
  },
  {
    name: 'Page',
    path: 'page',
    redirect: '/page',
    icon: <ArticleIcon />,
  },

  {
    name: 'Category',
    path: 'category',
    redirect: '/category',
    icon: <CategoryIcon />,
  },
  {
    name: 'Brand',
    path: 'brand',
    redirect: '/brand',
    icon: <DiamondIcon />,
  },
  {
    name: 'Comparisons',
    path: 'comparisons',
    redirect: '/comparisons',
    icon: <CompareIcon />,
  },
  {
    name: 'Filters',
    path: 'filter',
    redirect: '/filter',
    icon: <FilterAltIcon />,
  },
  {
    name: 'Sections',
    path: 'section',
    redirect: '/section',
    icon: <FormatAlignJustifyIcon />,
  },
  {
    name: 'Socail Links',
    path: 'social',
    redirect: '/social',
    icon: <ShareIcon />,
  },

  {
    name: 'Settings',
    path: 'settings',
    redirect: '/settings',
    icon: <SettingsIcon />,
  },
  // {
  //   name: 'Logout',
  //   path: 'logout',
  //   redirect: '/',
  //   icon: <LogoutIcon />,
  // },
];

const getTabPos = (endPoint) => {
  for (let i = 0; i < navs.length; i++) {
    if (navs[i].path === endPoint) return i;
  }
};
const endPoint = (pathname) => {
  return pathname.split('/').slice(-1)[0].split('?')[0];
};

export default function Sidebar() {
  let { pathname } = useLocation();
  const { logout, currentUser } = useAuth();
  const [sidebarPosition, setSidebarPos] = useState(
    getTabPos(getTabPos(endPoint(pathname)))
  );
  const navigate = useNavigate();
  const handleSideBarClick = (index, laptop) => {
    if (navs[index].path === 'logout') logout();
    navigate(navs[index].redirect);
    setSidebarPos(index);
  };

  return (
    <>
      <SideBar className="laptop-sidebar">
        <div
          style={{
            padding: '10px 0 16px 0px',
            borderBottom: '.1px solid grey',
          }}
        >
          {navs.map((value, index) =>
            index === sidebarPosition ? (
              <SideBarItemSelected
                onClick={() => handleSideBarClick(index, true)}
              >
                {value.icon}
                <SideBarItemSelectedText>{value.name}</SideBarItemSelectedText>
              </SideBarItemSelected>
            ) : (
              <SideBarItem onClick={() => handleSideBarClick(index, true)}>
                {value.icon}
                <SideBarItemText>{value.name}</SideBarItemText>
              </SideBarItem>
            )
          )}
        </div>
        <div
          style={{
            padding: '16px 20px',
          }}
        >
          <p style={{ color: '#909090', fontSize: '12px' }}>
            {`© ${new Date().getFullYear()} TheDeveloperX`}
          </p>
        </div>
      </SideBar>

      <TabSideBar
        className="tab-sidebar"
        style={{
          position: 'sticky',
          top: '40',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            padding: '0 0 16px 0',
            borderBottom: '.2px solid #e7e7e7',
          }}
        >
          {navs.map((value, index) =>
            index === sidebarPosition ? (
              <SideBarTabItemSelected onClick={() => handleSideBarClick(index)}>
                <TabItem>
                  {value.icon}
                  <TabItemText>{value.name}</TabItemText>
                </TabItem>
              </SideBarTabItemSelected>
            ) : (
              <SideBarTabItem onClick={() => handleSideBarClick(index)}>
                <TabItem>
                  {value.icon}
                  <TabItemText>{value.name}</TabItemText>
                </TabItem>
              </SideBarTabItem>
            )
          )}
        </div>
      </TabSideBar>
    </>
  );
}
