import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import axios from "axios";
import InputHeading from "../InputHeading";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "../InputLabel";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Dropzone from "react-dropzone";
import useAuth from "../../contexts/AuthContext";
import { TextArea } from "./components";
import { CDN_URL, BASE_URL } from "../../config";
import "./UploadProduct.css";
import { ReadContainer600 } from "../share";

export default function ({ edit }) {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    logo: "",
    name: "",
    title: "",
    metaDesc: "",
    metaTitle: "",
    keyword: "",
  });
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      axios
        .get(`${BASE_URL}/api/brand/${id}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            setData(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [edit]);

  const submitPost = (e) => {
    e.preventDefault();

    // if (currentUser && !currentUser.isAuth) {
    //   return alert('Please log in first.');
    // }

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });
    axios
      .post(
        edit ? `${BASE_URL}/api/brand/update` : `${BASE_URL}/api/brand/add`,
        {
          ...data,
          id: data.name.trim().toLowerCase().replace(" ", "-"),
        }
      )
      .then((response) => {
        if (response.data.success) {
          navigate("/brand");
        } else {
          alert("Failed while uploading your post. Please try again later.");
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        alert(
          response.err == undefined ? "Internal Server Error" : response.err
        );
        setLoading(false);
      });
  };

  const selectPhoto = (files) => {
    if (!files[0]) return;
    let formData = new FormData();
    formData.append("file", files[0]);
    axios
      .post(`${CDN_URL}/api/brand/logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData({ ...data, logo: res.data.filename });
        }
      });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Add Brand"
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/brand"
      />

      <div>
        <div style={{ marginTop: "18px" }}>
          <div>
            <Dropzone
              className="video-drop-zone"
              onDrop={selectPhoto}
              multiple={false}
              maxSize={800000000}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="hover-lightgrey"
                  style={{
                    width: "130px",
                    height: "120px",
                    display: "flex",
                    border: ".5px dashed lightgray",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />

                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    {data.logo ? (
                      <img
                        style={{ width: "100%" }}
                        alt="thumbnail"
                        src={`${CDN_URL}/cdn/brand/${data.logo}`}
                      />
                    ) : (
                      <div>
                        {uploadStatus.isUploading && (
                          <CircularProgress
                            disableShrink
                            size="24px"
                            style={{ color: "lightgrey" }}
                          />
                        )}
                        {uploadStatus.isUploading && (
                          <p style={{ color: "grey", fontSize: "12px" }}>
                            {`${uploadStatus.uploadPercentage}%`}
                          </p>
                        )}

                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              fontSize: "12px",
                              display: "flex",
                              flexDirection: "row",
                              fontWeight: "bold",
                              color: "rgb(99, 91, 255)",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <ArrowUpwardIcon
                              style={{ fontSize: "16px", marginRight: "2px" }}
                            />
                            <p style={{ margin: "0" }}>Logo</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>
        <div style={{ marginTop: "6px" }}>
          <InputLabel label="Brand Name" />
          <TextArea
            id="brand"
            label="brand"
            placeholder="name.."
            rows="1"
            style={{ maxWidth: "300px" }}
            onChange={(e) => setData({ ...data, name: e.currentTarget.value })}
            value={data.name}
          />
          <br />
          <InputLabel label="Page Title" />
          <textarea
            id="title"
            label="Page title"
            placeholder="Page title"
            style={{
              padding: "4px 6px",
              width: "100%",
              textAlign: "start",
              boxSizing: "border-box",
              fontSize: "1rem",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            rows="1"
            onChange={(e) => {
              setData({ ...data, title: e.currentTarget.value });
            }}
            value={data.title}
          />
          <InputLabel label="Meta title" />
          <textarea
            id="metaTitle"
            label="metaTitle"
            placeholder="Meta title"
            style={{
              padding: "4px 6px",
              width: "100%",
              textAlign: "start",
              boxSizing: "border-box",
              fontSize: "1rem",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            rows="3"
            onChange={(e) => {
              setData({ ...data, metaTitle: e.currentTarget.value });
            }}
            value={data.metaTitle}
          />
          <InputLabel label="Meta description" />
          <textarea
            id="metaDesc"
            label="metaDesc"
            placeholder="Within 160-300 char"
            style={{
              padding: "4px 6px",
              width: "100%",
              textAlign: "start",
              boxSizing: "border-box",
              fontSize: "1rem",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            rows="3"
            onChange={(e) => {
              setData({ ...data, metaDesc: e.currentTarget.value });
            }}
            value={data.metaDesc}
          />
          <InputLabel label="Keywords" />
          <textarea
            id="keywords"
            label="Keywords"
            placeholder="keywords"
            style={{
              padding: "4px 6px",
              width: "100%",
              textAlign: "start",
              boxSizing: "border-box",
              fontSize: "1rem",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            rows="2"
            onChange={(e) => {
              setData({ ...data, keyword: e.currentTarget.value });
            }}
            value={data.keyword}
          />
        </div>
      </div>

      <br />
    </ReadContainer600>
  );
}
