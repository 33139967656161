import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import EmptyView from '../EmptyView';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import NormalizeDashboard from '../NormalizeDashboard';
import { CDN_URL, CLIENT_URL, BASE_URL } from '../../config';
import { getFilterUrl } from '../../utils';
import BrandDD from '../BrandDD';
import { RowDivAlignCenterBetween } from '../share';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import SearchField from '../upload/SearchField';
import { Link, useSearchParams } from 'react-router-dom';

export default function () {
  const navigate = useNavigate();

  const [posts, setPosts] = useState([]);
  const [brands, setBrand] = useState([]);

  const [searchParams] = useSearchParams();
  const s_txt = searchParams.get('search');
  const pg = searchParams.get('pageNo');
  const perpage = searchParams.get('perPage');
  const brnd = searchParams.get('brand');
  const srt = searchParams.get('sort');
  const _sorttime = searchParams.get('sortTime');

  const [searchText, setSearchItem] = useState(s_txt == null ? '' : s_txt);
  const [showBrand, setShowBrand] = useState(brnd == null ? '' : brnd);
  const [pageNo, setPageNo] = useState(pg == null ? 0 : pg);
  const [perPage, setResultPerPage] = useState(perpage == null ? 10 : perpage);
  const [pageCount, setPageCount] = useState(1);
  const [sort, setSortPrice] = useState(srt == null ? 1 : srt);
  const [sortTime, setSortTime] = useState(_sorttime == null ? -1 : _sorttime);

  const handleChange = (event, value) => {
    console.log(pageNo);
    console.log(value);
    setPageNo(value);
  };

  const totalPages = (itemCount) => {
    let cout = Math.ceil(itemCount / perPage);
    console.log('total page:', cout);
    setPageCount(cout);
  };

  const sortByPrice = (s) => {
    setSortPrice(s);
    if (s && s !== sort)
      navigate(
        `/${getFilterUrl(searchText, showBrand, perPage, pageNo, s, sortTime)}`
      );
  };

  const fetch = (url) => {
    axios
      .get(`${BASE_URL}/api/${url}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.success) setPosts(res.data.data);
        else setPosts([]);
        totalPages(res.data.totalCount ? res.data.totalCount : 0);
      })
      .catch((err) => {
        console.log(err);
        setPosts([]);
        totalPages(0);
      });
  };

  const searchByText = (text) => {
    setSearchItem(text);
    axios
      .get(`${BASE_URL}/api/posts/adminSearch?search=${text}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.success) setPosts(res.data.data);
        else setPosts([]);
        totalPages(res.data.totalCount ? res.data.totalCount : 0);
      })
      .catch((err) => {
        console.log(err);
        setPosts([]);
        totalPages(0);
      });
  };
  useEffect(() => {
    fetch(
      getFilterUrl(
        searchText,
        showBrand == 'all-brands' ? '' : showBrand,
        perPage,
        pageNo,
        sort,
        sortTime
      )
    );
  }, [pg, perpage, brnd, srt]);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/brand`).then((res) => {
      if (res.data.success) {
        console.log(res.data.data);
        setBrand(res.data.data);
      } else {
        alert('Failed to get latestposts');
      }
    });
  }, []);
  const handleDelete = (url) => {
    axios.post(`${BASE_URL}/api/posts/delete/${url}`).then((res) => {
      if (res.data.success) {
        fetch(
          getFilterUrl(searchText, showBrand, perPage, pageNo, sort, sortTime)
        );
      } else {
        alert('Something wrong try again later..');
      }
    });
  };

  const filterBrand = (brandId) => {
    setShowBrand(brandId);
    setPageNo(0);
    navigate(
      `/${getFilterUrl(searchText, brandId, perPage, 1, sort, sortTime)}`
    );
  };

  return (
    <div>
      <RowDivAlignCenterBetween style={{ maxWidth: '920px' }}>
        <h1>Posts</h1>
        <div className='laptop-visible-only'><SearchField submit={searchByText} /></div>
        <BrandDD
          val={brands}
          onChange={(v) => {
            filterBrand(v);
          }}
        />
      </RowDivAlignCenterBetween>
      <div className='mobile-visible-only'><SearchField submit={searchByText} /></div>
      {/* {posts &&<Table data={posts}/>} */}
      {/* {posts ? (
        posts.map((postData, index) => <PostCard data={postData} />)
      ) : (
        <EmptyView />
      )} */}
      <table>
        {posts?.map((val, index) => (
          <NormalizeDashboard
            key={`item-${index}`}
            index={index}
            title={val.name}
            url={`${CLIENT_URL}/phones/${val.url}`}
            text={val.price}
            image={`${CDN_URL}/cdn/medium/${val.media[0].thumbnail}`}
            onEdit={() => {
              navigate(`/post/edit/${val._id}`);
            }}
            onDelete={() => {
              handleDelete(val.url);
            }}
          />
        ))}
      </table>
      <div style={{ margin: '26px 0' }}>
        <Pagination
          renderItem={(item) => {
            return (
              <PaginationItem
                component={Link}
                to={`/${getFilterUrl(
                  searchText,
                  showBrand,
                  perPage,
                  item.page,
                  sort,
                  sortTime
                )}`}
                {...item}
              />
            );
          }}
          count={pageCount}
          variant="outlined"
          shape="rounded"
          page={pageNo}
          onChange={handleChange}
        />
      </div>
      <div style={{ maxWidth: '920px' }}>
        {posts && posts.length == 0 && <EmptyView />}
      </div>
    </div>
  );
}
