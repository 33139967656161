import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import { Provider } from "react-redux";
import Store from "./store";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={Store}>
        <App />
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { AuthProvider } from './contexts/AuthContext';
// import { Provider } from 'react-redux';
// import Store from './store';
// import './index.css';
// import App from './App';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <AuthProvider>
//       <Provider store={Store}>
//         <App />
//       </Provider>
//     </AuthProvider>
//   </React.StrictMode>
// );