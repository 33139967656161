import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { BASE_URL } from '../config';

const AuthContext = React.createContext();

export default function useAuth() {
  return useContext(AuthContext);
}
export const baseUrl = `${BASE_URL}/api/admin`;

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(['admin']);

  const handle = (token, expr) => {
    setCookie(process.env.REACT_APP_COOKIE_AUTH_ADMIN_TOKEN, token, {
      path: '/',
      maxAge: expr,
    });
  };

  async function createAdmin(data) {
    return await axios
      .post(`${baseUrl}/create`, data)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  async function login(data) {
    return axios
      .post(`${baseUrl}/login`, data)
      .then((res) => {
        console.log(res.data);
        handle(res.data.token, 2592000);
        setCurrentUser(res.data);
        return res;
      })
      .catch(({ response }) => response);
  }

  function logout() {
    const request = axios
      .post(`${baseUrl}/logout`, { cookie: cookies })
      .then((response) => {
        handle('', 0);
        setCurrentUser(response.data);
      });

    return request;
  }

  useEffect(() => {
    const request = axios
      .post(`${baseUrl}/auth`, { cookie: cookies })
      .then((res) => {
        console.log(res.data);
        setCurrentUser(res.data);
        setLoading(false);
      });

    return request;
  }, []);

  const value = {
    currentUser,
    login,
    createAdmin,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
