import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useAuth from '../contexts/AuthContext';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router';
import { ProfileCircle } from './ProfileEditComponents';
import { CDN_URL, BASE_URL } from '../config';
import CreateUser from './CreateUser';
import { TextArea } from './upload/components';
import InputLabel from './InputLabel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Dropzone from 'react-dropzone';
import { SpaceBetween } from './share';
import NormalizeView from './NormalizeView';
import './upload/UploadProduct.css';
const init = {
  logo: '',
  platform: '',
  link: '',
};
export default function ProfileEditForm() {
  const [userData, setUser] = useState();
  const { currentUser } = useAuth();
  const [image, setImage] = useState();
  const [createUser, setCreateUser] = useState(false);
  const navigate = useNavigate();
  const fetchAdmin = () => {
    axios.get(`${BASE_URL}/api/admin/${currentUser.userId}`).then((res) => {
      console.log(res.data);
      if (res.data.success) {
        setUser(res.data.user);
      }
    });
  };
  useEffect(() => {
    fetchAdmin();
  }, []);

  const schema = yup.object({
    name: yup.string().required("Name can't be empty"),
    username: yup.string().required('Unique username is required!'),
    desc: yup
      .string()
      .max(256, 'Please keep your description length within 256 charecters'),
  });

  async function updateUser(data) {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${BASE_URL}/api/admin/update/${currentUser.userId}`, data)
        .then((res) => {
          console.log(res.data);
          if(res.data.success){
            alert('Information updated successfully!');
          }
          fetchAdmin();
          navigate(`/profile`);
          resolve('success');
        })
        .catch(({ response }) => {
          alert('Error occured while saving data! Please try again later.');
          resolve(response);
        });
    });
  }

  const handleSubmitForm = async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    var data = {
      name: values.name,
      desc: values.desc,
      username: values.username,
      image: image,
    };
    await updateUser(data);
    setSubmitting(false);
  };

  const selectProfilePhoto = (event) => {
    if (!event.target.files[0]) return;
    let formData = new FormData();
    formData.append('file', event.target.files[0]);

    axios
      .post(`${CDN_URL}/api/user/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          setImage(res.data.filename);
        }
      });
  };

  //-------------------------------------------------

  const [data, setData] = useState(init);

  const addSocialLink = async () => {
    let freshCopy = [];
    if (userData.socialLinks && userData.socialLinks.length > 0) {
      freshCopy = userData.socialLinks;
    }
    freshCopy.push(data);
    const freshData = { ...userData, socialLinks: freshCopy };
    await updateUser(freshData);
    setData(init);
  };

  const selectPhoto = (files) => {
    if (!files[0]) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    axios
      .post(`${CDN_URL}/api/social/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData({ ...data, logo: res.data.filename });
        }
      });
  };
  //------------------------------------------------

  return userData ? (
    <div
      style={{
        padding: '36px',
        width: '100%',
        maxWidth: '920px',
      }}
    >
      <SpaceBetween>
        <div />
        {!createUser && (
          <Button
            variant="outlined"
            color="warning"
            onClick={() => setCreateUser(true)}
          >
            Create User
          </Button>
        )}
      </SpaceBetween>
      {createUser ? (
        <div>
          <CreateUser />
        </div>
      ) : (
        <div>
          <Formik
            initialValues={userData}
            validationSchema={schema}
            onSubmit={handleSubmitForm}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                style={{ display: 'flex', flexDirection: 'column' }}
                onSubmit={handleSubmit}
              >
                <ProfileCircle>
                  {image ? (
                    <img
                      className="previous-photo"
                      src={`${CDN_URL}/cdn/photo/${image}`}
                    ></img>
                  ) : (
                    <img
                      className="previous-photo"
                      src={`${CDN_URL}/cdn/photo/${userData.image}`}
                    ></img>
                  )}
                  <p>Upload</p>
                  <input
                    className="update-photo"
                    type="file"
                    accept="image/*"
                    onChange={selectProfilePhoto}
                  />
                </ProfileCircle>

                <TextField
                  sx={{ marginTop: '1.5rem' }}
                  variant="outlined"
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.name && touched.name ? errors.name : null}
                  error={errors.name && touched.name}
                />
                <TextField
                  sx={{ marginTop: '1.5rem' }}
                  variant="outlined"
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.username && touched.username ? errors.username : null
                  }
                  error={errors.username && touched.username}
                />
                <br />

                <TextField
                  id="desc"
                  label="Description"
                  name="desc"
                  multiline
                  rows={3}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.desc}
                  helperText={errors.desc && touched.desc ? errors.desc : null}
                  error={errors.desc && touched.desc}
                />
                <br />
                <TextField
                  disabled
                  id="email"
                  label="Email"
                  value={values.email}
                  defaultValue={values.email}
                />
                <br />

                <Button variant="contained" type="submit" disableElevation>
                  SAVE
                </Button>
              </form>
            )}
          </Formik>

          <div>
            <br />
            <br />
            <hr />
            <br />
            {userData.socialLinks &&
              userData.socialLinks.map((val, index) => {
                return (
                  <NormalizeView
                    title={val.platform}
                    text={val.link}
                    image={`${CDN_URL}/cdn/social/${val.logo}`}
                    onDelete={() => {
                      const freshCopy = userData.socialLinks.filter(
                        (item) => item._id != val._id
                      );
                      const freshData = { ...userData, socialLinks: freshCopy };
                      updateUser(freshData);
                    }}
                  />
                );
              })}
            <br />
            <hr />
            <br />
            <div style={{ marginTop: '18px' }}>
              <div>
                <Dropzone
                  className="video-drop-zone"
                  onDrop={selectPhoto}
                  multiple={false}
                  maxSize={800000000}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="hover-lightgrey"
                      style={{
                        width: '100px',
                        height: '100px',
                        display: 'flex',
                        border: '.5px dashed lightgray',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />

                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          overflow: 'hidden',
                        }}
                      >
                        {data.logo ? (
                          <img
                            style={{ width: '100%' }}
                            alt="thumbnail"
                            src={`${CDN_URL}/cdn/social/${data.logo}`}
                          />
                        ) : (
                          <div>
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  fontSize: '12px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  fontWeight: 'bold',
                                  color: 'rgb(99, 91, 255)',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <ArrowUpwardIcon
                                  style={{
                                    fontSize: '16px',
                                    marginRight: '2px',
                                  }}
                                />
                                <p style={{ margin: '0' }}>Logo</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
            <div style={{ marginTop: '6px' }}>
              <InputLabel label="Platform" />
              <TextArea
                id="platform"
                label="platform"
                placeholder="platform name"
                rows="1"
                style={{ maxWidth: '300px' }}
                onChange={(e) =>
                  setData({ ...data, platform: e.currentTarget.value })
                }
                value={data.platform}
              />
              <InputLabel label="Link" />
              <TextArea
                id="link"
                label="link"
                placeholder="social link"
                rows="1"
                style={{ maxWidth: '300px' }}
                onChange={(e) =>
                  setData({ ...data, link: e.currentTarget.value })
                }
                value={data.link}
              />
            </div>
            <br />
            <Button
              variant="contained"
              disableElevation
              size="small"
              onClick={addSocialLink}
            >
              Admin social link
            </Button>
          </div>
        </div>
      )}
    </div>
  ) : (
    <span>loading...</span>
  );
}
