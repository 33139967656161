import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import InputHeading from '../InputHeading';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '../InputLabel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Dropzone from 'react-dropzone';
import useAuth from '../../contexts/AuthContext';
import { TextArea } from './components';
import { CDN_URL, BASE_URL } from '../../config';
import './UploadProduct.css';
import { ReadContainer600 } from '../share';

export default function ({ edit, handleClose }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState({
    logo: '',
    name: '',
  });
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [loading, setLoading] = useState(false);

  const submitPost = (e) => {
    e.preventDefault();

    if (currentUser && !currentUser.isAuth) {
      return alert('Please log in first.');
    }

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });
    axios
      .post(`${BASE_URL}/api/social/add`, {
        ...data,
        id: data.name.trim().toLowerCase().replace(' ', '-'),
      })
      .then((response) => {
        if (response.data.success) {
          navigate('/social');
        } else {
          alert('Failed while uploading your post. Please try again later.');
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        alert(
          response.err == undefined ? 'Internal Server Error' : response.err
        );
        setLoading(false);
      });
  };

  const selectPhoto = (files) => {
    if (!files[0]) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    axios
      .post(`${CDN_URL}/api/social/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData({ ...data, logo: res.data.filename });
        }
      });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Add Social Link"
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/social"
      />

      <div>
        <div style={{ marginTop: '18px' }}>
          <div>
            <Dropzone
              className="video-drop-zone"
              onDrop={selectPhoto}
              multiple={false}
              maxSize={800000000}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="hover-lightgrey"
                  style={{
                    width: '130px',
                    height: '120px',
                    display: 'flex',
                    border: '.5px dashed lightgray',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />

                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    {data.logo ? (
                      <img
                        style={{ width: '100%' }}
                        alt="thumbnail"
                        src={`${CDN_URL}/cdn/social/${data.logo}`}
                      />
                    ) : (
                      <div>
                        {uploadStatus.isUploading && (
                          <CircularProgress
                            disableShrink
                            size="24px"
                            style={{ color: 'lightgrey' }}
                          />
                        )}
                        {uploadStatus.isUploading && (
                          <p style={{ color: 'grey', fontSize: '12px' }}>
                            {`${uploadStatus.uploadPercentage}%`}
                          </p>
                        )}

                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              fontSize: '12px',
                              display: 'flex',
                              flexDirection: 'row',
                              fontWeight: 'bold',
                              color: 'rgb(99, 91, 255)',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <ArrowUpwardIcon
                              style={{ fontSize: '16px', marginRight: '2px' }}
                            />
                            <p style={{ margin: '0' }}>Logo</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>
        <div style={{ marginTop: '6px' }}>
          <InputLabel label="Platform" />
          <TextArea
            id="platform"
            label="platform"
            placeholder="platform name.."
            rows="1"
            style={{ maxWidth: '300px' }}
            onChange={(e) =>
              setData({ ...data, platform: e.currentTarget.value })
            }
            value={data.platform}
          />
          <InputLabel label="Link" />
          <TextArea
            id="link"
            label="link"
            placeholder="your link.."
            rows="1"
            style={{ maxWidth: '300px' }}
            onChange={(e) => setData({ ...data, link: e.currentTarget.value })}
            value={data.link}
          />
        </div>
      </div>

      <br />
    </ReadContainer600>
  );
}
