import React from "react";
import EmptyIcon from "../assets/empty.png";
import styled from "styled-components";

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: gray;
  min-height: 300px;
  img {
    height: 64px;
    width: 64px;
  }
`;
export default function EmptyView() {
  return (
    <IconContainer>
      <img src={EmptyIcon} />
      <br/>
      <p> No file found!</p>
    </IconContainer>
  );
}
