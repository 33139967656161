// const gsmarenaExtruct = (gsmarena, brand, price) => {
//   let filtering = {};
//   // General
//   // brand:

//   // released: if contain Released?
//   // take next '5' char then trim it. from `Announced` ->  `LAUNCH` spec

//   // availability: take `Status` -> `LAUNCH` spec

//   // price: take from mobiledokanco

//   //--------
//   // Network
//   // check how many field you get
//   // 2G, 3G, 4G, 5G

//   // if name contains '2G' add 2G data
//   // 2g[]: split at '-' take first portion replace GSM, '' then split at '/' and trim each item
//   //     then add 'GSM '+item as an array

//   // 3g[]: same as 2G just replace with HSPA and
//   //     extra split at '(' and take first portion

//   // 4g[]: split at ','
//   //     check the value and take values according to that number (from value set defination json defined in gsmarena)

//   // 5g[]: same as 4G
//   //     extra split at ' ' and take only number code

//   //--------
//   // SIM
//   // size: check if it contain 'Micro-SIM/Nano-SIM/eSIM/' else 'Mini-SIM'
//   // multiple: check if it contain 'Dual SIM/Triple SIM/Quad SIM' else 'Single SIM'
//   // from `SIM` -> `BODY`

//   //--------
//   // Body
//   // form_factor: input it
//   // option- 'Bar, Flip up, Flip down, Slide, Swivel, Watch, Other, Foldable'

//   // keyboard: input it
//   // option - 'With QWERTY, Withoud QWERTY'
//   // height: input in mm
//   // width: input in mm
//   // thickness: input in mm
//   // weight: input in mm
//   // ip_cert:
//   // options- 'IP5x/IP6x/MIL-STD-810D/MIL-STD-810F/MIL-STD-810G/MIL-STD-810H'
//   // color: input
//   // back_material:
//   // options-

//   //--------
//   // Platform

//   // Memory

//   // Display

//   // Main camera

//   // Selfie camera

//   // Audio

//   // Sensors

//   // Connectivity

//   // Battery

//   // Charging

//   // Misc

//   return {};
// };

const removeLastComma = (str) => {
  if (str.charAt(str.length - 1) === ',') {
    return str.slice(0, -1);
  }
  return str;
};

const gsmarenaExtruct = (gsmarena) => {
  let filters = {};
  // connectivity:
  // from `COMMS` take only name if value exist
  filters.connectivity = '';
  try {
    for (let i = 0; i < gsmarena.specs.length; i++) {
      if (gsmarena.specs[i].name === 'Comms') {
        for (let index = 0; index < gsmarena.specs[i].values.length; index++) {
          if (gsmarena.specs[i].values[index].value) {
            filters.connectivity += gsmarena.specs[i].values[index].name + ', ';
          }
        }
      }
    }
  } catch (e) {}
  filters.connectivity = removeLastComma(filters.connectivity.trim());

  // cpu:
  // from 'PLATFORM' -> 'CPU'
  // split at 'x' and split at ' ' and take first item each
  filters.cpu = '';
  try {
    for (let i = 0; i < gsmarena.specs.length; i++) {
      if (gsmarena.specs[i].name === 'Platform') {
        for (let index = 0; index < gsmarena.specs[i].values.length; index++) {
          if (gsmarena.specs[i].values[index].name === 'CPU') {
            let ff = gsmarena.specs[i].values[index].value.split('x');
            for (let xx = 0; xx < ff.length; xx++) {
              if (xx == 0) continue;
              let vvv = ff[xx].split(' ');
              if (!isNaN(vvv[0])) {
                filters.cpu += vvv[0] + ' GHz, ';
              }
            }
          }
        }
      }
    }
  } catch (e) {}
  filters.cpu = removeLastComma(filters.cpu.trim());

  // network:
  // from 'NETWORK' take only name if contain '2G/3G/4G/5G'
  filters.network = '';
  try {
    for (let i = 0; i < gsmarena.specs.length; i++) {
      if (gsmarena.specs[i].name === 'Network') {
        for (let index = 0; index < gsmarena.specs[i].values.length; index++) {
          // console.log(gsmarena.specs[i].values[index].name.indexOf('2G') > -1);
          // console.log(gsmarena.specs[i].values[index].name.includes('2G'));
          if (gsmarena.specs[i].values[index].name.includes('2G')) {
            filters.network += '2G, ';
          }
          if (gsmarena.specs[i].values[index].name.includes('3G')) {
            filters.network += '3G, ';
          }
          if (gsmarena.specs[i].values[index].name.includes('4G')) {
            filters.network += '4G, ';
          }
          if (gsmarena.specs[i].values[index].name.includes('5G')) {
            filters.network += '5G, ';
          }
          if (gsmarena.specs[i].values[index].name.includes('6G')) {
            filters.network += '6G, ';
          }
        }
      }
    }
  } catch (e) {}
  filters.network = removeLastComma(filters.network.trim());

  // sensors:
  // from 'FEATURES' take 'Sensors' value
  filters.sensors = '';
  try {
    for (let i = 0; i < gsmarena.specs.length; i++) {
      if (gsmarena.specs[i].name === 'Features') {
        for (let index = 0; index < gsmarena.specs[i].values.length; index++) {
          if (gsmarena.specs[i].values[index].name === 'Sensors') {
            filters.sensors += gsmarena.specs[i].values[index].value;
          }
        }
      }
    }
  } catch (e) {}
  filters.sensors = removeLastComma(filters.sensors.trim());

  // sim:
  // from 'BODY' -> 'SIM' check if it contain 'Dual SIM/Triple SIM/Quad SIM' else 'Single SIM'
  filters.sim = '';
  try {
    for (let i = 0; i < gsmarena.specs.length; i++) {
      if (gsmarena.specs[i].name === 'Body') {
        for (let index = 0; index < gsmarena.specs[i].values.length; index++) {
          if (gsmarena.specs[i].values[index].name === 'SIM') {
            let vv = gsmarena.specs[i].values[index].value.toLowerCase();
            if (vv.includes('single sim')) {
              filters.sim += 'Single SIM, ';
            }
            if (vv.includes('dual sim')) {
              filters.sim += 'Dual SIM, ';
            }
            if (vv.includes('triple sim')) {
              filters.sim += 'Triple SIM, ';
            }
            if (vv.includes('quad sim')) {
              filters.sim += 'Quad SIM, ';
            }
          }
        }
      }
    }
  } catch (e) {}

  if (filters.sim == '') {
    filters.sim += 'Single SIM';
  }
  filters.sim = removeLastComma(filters.sim.trim());

  // storage:
  // from 'MEMORY' -> 'Internal' split ' ' and take first item
  filters.storage = '';
  try {
    for (let i = 0; i < gsmarena.specs.length; i++) {
      if (gsmarena.specs[i].name === 'Memory') {
        for (let index = 0; index < gsmarena.specs[i].values.length; index++) {
          if (gsmarena.specs[i].values[index].name === 'Internal') {
            let ff = gsmarena.specs[i].values[index].value.split(' ');
            filters.storage += ff[0];
          }
        }
      }
    }
  } catch (e) {}
  filters.storage = removeLastComma(filters.storage.trim());

  // *display:
  // from 'DISPLAY' -> 'Size' split at ',' and take first item
  filters.display = '';
  try {
    for (let i = 0; i < gsmarena.specs.length; i++) {
      if (gsmarena.specs[i].name === 'Display') {
        for (let index = 0; index < gsmarena.specs[i].values.length; index++) {
          if (gsmarena.specs[i].values[index].name === 'Size') {
            let ff = gsmarena.specs[i].values[index].value.split(',');
            filters.display += ff[0];
          }
        }
      }
    }
  } catch (e) {}
  filters.display = removeLastComma(filters.display.trim());

  // os:
  // from 'PLATFORM' -> 'OS' take value split at ',' and take first item
  filters.os = '';
  try {
    for (let i = 0; i < gsmarena.specs.length; i++) {
      if (gsmarena.specs[i].name === 'Platform') {
        for (let index = 0; index < gsmarena.specs[i].values.length; index++) {
          if (gsmarena.specs[i].values[index].name === 'OS') {
            let ff = gsmarena.specs[i].values[index].value.split(',');
            filters.os += ff[0];
          }
        }
      }
    }
  } catch (e) {}
  filters.os = removeLastComma(filters.os.trim());

  // *battery:
  // from scrapped heighlight field take battery data
  filters.battery = '';
  try {
    //   filters.battery = gsmarena.highlights[3].values[0];
    for (let i = 0; i < gsmarena.highlights.length; i++) {
      if (gsmarena.highlights[i].name === 'Battery') {
        if (gsmarena.highlights[i].values)
          filters.battery = gsmarena.highlights[i].values[0];
      }
    }
  } catch (e) {}

  // camera:
  // from scrapped heighlight field take camera data
  filters.camera = '';
  try {
    //   filters.camera = gsmarena.highlights[1].values[0];
    for (let i = 0; i < gsmarena.highlights.length; i++) {
      if (gsmarena.highlights[i].name === 'Camera') {
        if (gsmarena.highlights[i].values)
          filters.camera = gsmarena.highlights[i].values[0];
      }
    }
  } catch (e) {}

  // *ram:
  // from scrapped heighlight field take ram data
  filters.ram = '';
  try {
    //   filters.ram = gsmarena.highlights[2].values[0];
    for (let i = 0; i < gsmarena.highlights.length; i++) {
      if (gsmarena.highlights[i].name === 'Processor') {
        if (gsmarena.highlights[i].values)
          filters.ram = gsmarena.highlights[i].values[0].split(' ')[0];
      }
    }
  } catch (e) {}

  // model:
  // from 'MISC' -> 'Models' take value
  filters.model = '';
  try {
    for (let i = 0; i < gsmarena.specs.length; i++) {
      if (gsmarena.specs[i].name === 'Misc') {
        for (let index = 0; index < gsmarena.specs[i].values.length; index++) {
          if (gsmarena.specs[i].values[index].name === 'Models') {
            filters.model = gsmarena.specs[i].values[index].value;
          }
        }
      }
    }
  } catch (e) {}
  
  return filters;
};

const mobiledokancoExtruct = (mobiledokanco, filters) => {
  // price:
  // from scrapped price field take price data
  if (filters) {
    return { ...filters, price: mobiledokanco.price };
  }

  //--
  // os:
  // from scrapped features field take os data

  // *ram:
  // split at '/' and then split at ' ' and take only first item from fist split array

  return { price: mobiledokanco.price };
};

export const gsmarenaFilter = (data) => {
  return gsmarenaExtruct(data);
};

export const mobiledokancoFilter = (data, filters) => {
  return mobiledokancoExtruct(data, filters ? filters : {});
};
