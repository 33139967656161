import { GET_ALL_POSTS } from "../actions/types";

const intialState = {
  posts: [],
  loading: true,
};

export default function (state = intialState, action) {
  switch (action.type) {
    case GET_ALL_POSTS:
      return {
        ...state,
        isFetched: action.payload.isFetched,
        posts: action.payload.posts,
        loading: action.payload.loading,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
