import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import InputHeading from '../InputHeading';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '../InputLabel';
import { BlogMedia } from '../ProfileViewComponents';
import placeholder from '../../assets/placeholder.png';
import BlogSectionInput from './BlogSectionInput';
import { useParams } from 'react-router';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Dropzone from 'react-dropzone';
import useAuth from '../../contexts/AuthContext';
import { TextArea } from './components';
import { BASE_URL, CDN_URL, DOMAIN } from '../../config';
import { ReadContainer600 } from '../share';
import AdvancedEditor from './AdvancedEditor';
import './UploadProduct.css';

export default function ({ edit }) {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [htmlContent, setHtmlContent] = useState(''); // empty is important here to print desc after api response
  const inputRef = useRef(null);
  const [data, setData] = useState(() => {
    return {
      authorName: currentUser.user?.name,
      authorImage: currentUser.user?.image,
      author: currentUser.userId,
      title: '',
      imageName: '',
      url: '',
      metaDesc: '',
      metaTitle: '',
      desc: '',
      delta: '',
      html: '',
      sections: [],
    };
  });
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [imgUpload, setUploadedImg] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      axios
        .get(`${BASE_URL}/api/blog/byId/${id}`)
        .then((res) => {
          if (res.data.success) {
            setData(res.data.data);
            setHtmlContent(res.data.data.html || ' ');
            setUploadedImg(res.data.data.image);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [edit]);

  const changeTitle = (event) => {
    setData({
      ...data,
      title: event.currentTarget.value,
      url: event.currentTarget.value
        .toString()
        .toLowerCase()
        .replaceAll(' ', '-'),
    });
  };
  const changeKeyword = (event) => {
    setData({ ...data, keyword: event.currentTarget.value });
  };

  const submitPost = (e) => {
    e.preventDefault();

    if (currentUser && !currentUser.isAuth) {
      return alert('Please log in first.');
    }

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });
    axios
      .post(edit ? `${BASE_URL}/api/blog/update` : `${BASE_URL}/api/blog/add`, {
        ...data,
        desc: inputRef.current?.editor.getText(),
        html: inputRef.current?.value,
        image: imgUpload,
      })
      .then((res) => {
        if (res.data.success) {
          navigate('/blog');
        } else {
          alert('Failed while uploading your post. Please try again later.');
        }
        setLoading(false);
      })
      .catch((err) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        console.log(err);
        setLoading(false);
      });
  };

  const selectPhoto = (files) => {
    // if (!event.target.files[0]) return;
    // let formData = new FormData();
    // formData.append('file', event.target.files[0]);
    if (!files[0]) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    axios
      .post(`${CDN_URL}/api/user/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData({ ...data, authorImage: res.data.filename });
        }
      });
  };

  const onDrop = (event) => {
    if (!event.target.files[0]) return;
    let formData = new FormData();
    formData.append('file', event.target.files[0]);

    const config = {
      header: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadState({
          ...uploadStatus,
          uploadPercentage: percentCompleted,
          uploadCompleted: false,
          isUploading: true,
        });
      },
    };
    axios.post(`${CDN_URL}/api/blog/media`, formData, config).then((res) => {
      if (res.data.success) {
        console.log(res.data);
        setUploadedImg(res.data.filename);
      } else {
        alert('failed to save the files');
      }
      setUploadState({
        ...uploadStatus,
        uploadPercentage: 0,
        uploadCompleted: true,
        isUploading: false,
      });
    });
  };

  const specsValuesChanged = (secIndex, o) => {
    console.log(o);
    const fields = data.sections;
    if (o == null) {
      // remove section
      fields.pop(secIndex);
    } else {
      if (fields.length <= secIndex) fields.push(o);
      // adding
      else fields[secIndex] = o; // replacing
    }
    setData({ ...data, sections: fields });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Blog Post"
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/blog"
      />
      <form onSubmit={submitPost} className="upload-moadal">
        <InputLabel label={`${DOMAIN}/reviews/${data.url}`} />
        <TextArea
          id="url"
          label="url"
          rows="1"
          onChange={(e) => {
            setData({
              ...data,
              url: e.currentTarget.value.toLowerCase().replaceAll(' ', '-'),
            });
          }}
          value={data.url}
        />
        <InputLabel label="Headline" />
        <TextArea
          id="title"
          label="title"
          rows="1"
          onChange={changeTitle}
          value={data.title}
        />
        <BlogMedia>
          {imgUpload ? (
            <img
              className="previous-photo"
              src={`${CDN_URL}/cdn/photo/${imgUpload}`}
            ></img>
          ) : (
            <img className="placeholder" src={placeholder}></img>
          )}
          <p>Upload</p>
          <input
            className="update-photo"
            type="file"
            accept="image/*"
            onChange={(event) => onDrop(event)}
          />
        </BlogMedia>
        <InputLabel label="Image name" />
        <TextArea
          id="imageName"
          label="imageName"
          placeholder="Image name"
          rows="1"
          onChange={(e) => {
            setData({ ...data, imageName: e.currentTarget.value });
          }}
          value={data.imageName}
        />
        {edit && htmlContent && (
          <AdvancedEditor value={htmlContent} ref={inputRef} />
        )}
        {!edit && <AdvancedEditor value={htmlContent} ref={inputRef} />}
        <br />
        <BlogSectionInput val={data} onChange={specsValuesChanged} />
        <br />
        <InputLabel
          label="Key words"
          tooltip="Use comma(,) to separate each keywords."
        />
        <TextArea
          id="keyword"
          label="keywords"
          rows="3"
          onChange={changeKeyword}
          value={data.keyword}
        />
        <br />
        <InputLabel label="Meta title" />
        <textarea
          id="metaTitle"
          label="metaTitle"
          placeholder="Meta title"
          style={{
            padding: '4px 6px',
            width: '100%',
            textAlign: 'start',
            boxSizing: 'border-box',
            fontSize: '1rem',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          }}
          rows="4"
          onChange={(e) => {
            setData({ ...data, metaTitle: e.currentTarget.value });
          }}
          value={data.metaTitle}
        />
        <br />
        <InputLabel label="Meta description" />
        <textarea
          id="metaDesc"
          label="metaDesc"
          placeholder="Within 160-300 char"
          style={{
            padding: '4px 6px',
            width: '100%',
            textAlign: 'start',
            boxSizing: 'border-box',
            fontSize: '1rem',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          }}
          rows="4"
          onChange={(e) => {
            setData({ ...data, metaDesc: e.currentTarget.value });
          }}
          value={data.metaDesc}
        />

        <br />
        <br />
        <br />
        <h3 className="input-section-desc">Author Section</h3>
        <hr />
        <div>
          <div style={{ marginTop: '18px' }}>
            <div>
              <Dropzone
                className="video-drop-zone"
                onDrop={selectPhoto}
                multiple={false}
                maxSize={800000000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="hover-lightgrey"
                    style={{
                      width: '130px',
                      height: '120px',
                      display: 'flex',
                      border: '.5px dashed lightgray',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />

                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      {data.authorImage ? (
                        <img
                          style={{ width: '100%' }}
                          alt="thumbnail"
                          src={`${CDN_URL}/cdn/photo/${data.authorImage}`}
                        />
                      ) : (
                        <div>
                          {uploadStatus.isUploading && (
                            <CircularProgress
                              disableShrink
                              size="24px"
                              style={{ color: 'lightgrey' }}
                            />
                          )}
                          {uploadStatus.isUploading && (
                            <p style={{ color: 'grey', fontSize: '12px' }}>
                              {`${uploadStatus.uploadPercentage}%`}
                            </p>
                          )}

                          <div style={{ display: 'flex' }}>
                            <div
                              style={{
                                fontSize: '12px',
                                display: 'flex',
                                flexDirection: 'row',
                                fontWeight: 'bold',
                                color: 'rgb(99, 91, 255)',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{ fontSize: '16px', marginRight: '2px' }}
                              />
                              <p style={{ margin: '0' }}>Image</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
          <div style={{ marginTop: '6px' }}>
            <InputLabel label="Name" />
            <TextArea
              id="authorName"
              label="authorName"
              placeholder="Author's Name"
              rows="1"
              style={{ maxWidth: '300px' }}
              onChange={(e) =>
                setData({ ...data, authorName: e.currentTarget.value })
              }
              value={data.authorName}
            />
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
      </form>
    </ReadContainer600>
  );
}
