import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import useAuth from '../contexts/AuthContext.js';
import SubmitLoading from '../components/SubmitLoading.js';
import CircularProgress from '@mui/material/CircularProgress';
import { ReadContainer } from '../components/share.js';
import { MoneyFormatInt } from '../utils.js';
import SingleInput from '../components/SingleInput.js';
import CheckView from '../components/CheckView.js';
import RangeInput from '../components/RangeInput.js';
import './style.css';
import { BASE_URL } from '../config.js';
import styled from 'styled-components';

const Title = styled.h2`
  padding: 0;
  margin: 0;
  margin-top: 20px;
  color: #051145;
`;

const Gap = () => {
  return (
    <div>
      <br />
      <br />
      <Divider />
    </div>
  );
};

export const filterExampleData = {
  battery: [
    { name: '800 mAh', range: { min: 0, max: 800 } },
    { name: '1001 - 1500 mAh', range: { min: 1001, max: 1500 } },
    { name: '1501 - 2000 mAh', range: { min: 1501, max: 2000 } },
    { name: '2001 - 3000 mAh', range: { min: 2001, max: 3000 } },
    { name: '3001 - 4000 mAh', range: { min: 3001, max: 4000 } },
    { name: '4001 - 5000 mAh', range: { min: 4001, max: 5000 } },
    { name: '5001 - 6000 mAh', range: { min: 5001, max: 6000 } },
    { name: '6001 - 7000 mAh', range: { min: 6001, max: 7000 } },
    { name: 'Above 7000 mAh', range: { min: 7001, max: 99999 } },
  ],
  camera: [
    { name: '1.2MP' },
    { name: '1MP' },
    { name: '2MP' },
    { name: '5MP' },
    { name: '8MP' },
    { name: '12MP' },
    { name: '13MP' },
    { name: '16MP' },
    { name: '20MP' },
    { name: '22MP' },
    { name: '23MP' },
    { name: '24MP' },
    { name: '32MP' },
    { name: '48MP' },
    { name: '50MP' },
    { name: '64MP' },
    { name: '108MP' },
    { name: '200MP' },
  ],
  connectivity: [
    { name: 'Bluetooth' },
    { name: 'GPS' },
    { name: 'HDMI' },
    { name: 'Infrared port' },
    { name: 'Radio' },
    { name: 'USB' },
    { name: 'USB 2.0' },
    { name: 'USB 2.0' },
    { name: 'USB Type-C' },
    { name: 'WLAN' },
  ],
  cpu: [
    { name: '1.1 - 1.5 GHz', range: { min: 1.1, max: 1.5 } },
    { name: '1.6 - 2.0 GHz', range: { min: 1.6, max: 2.0 } },
    { name: '2.1 - 2.5 GHz', range: { min: 2.1, max: 2.5 } },
    { name: '2.6 - 3.0 GHz', range: { min: 2.6, max: 3.0 } },
    { name: '3.0 - 4.1 GHz', range: { min: 3.0, max: 4.1 } },
    { name: 'Above 4.1 GHz', range: { min: 4.2, max: 999 } },
  ],
  display: [
    { name: 'Under 3 inches', range: { min: 0, max: 3.0 } },
    { name: '3.1 - 4.0 inches', range: { min: 3.1, max: 4.0 } },
    { name: '4.1 - 5.0 inches', range: { min: 4.1, max: 5.0 } },
    { name: '5.1 - 6.0 inches', range: { min: 5.1, max: 6.0 } },
    { name: '6.1 - 7.0 inches', range: { min: 6.1, max: 7.0 } },
    { name: '7.1 - 10 inches', range: { min: 7.1, max: 10 } },
    { name: 'Above 10 inches', range: { min: 10.1, max: 99 } },
  ],
  network: [
    { name: '2G' },
    { name: '3.5G' },
    { name: '3G' },
    { name: '4G' },
    { name: '5G' },
  ],
  os: [
    { name: 'Android' },
    { name: 'Symbian' },
    { name: 'Windows Phone' },
    { name: 'iOS' },
  ],
  price: [
    { name: '0 to 10,000 TK', range: { min: 0, max: 10000 } },
    { name: '10,001 to 20,000 TK', range: { min: 10000, max: 20000 } },
    { name: '20,001 to 30,000 TK', range: { min: 20001, max: 30000 } },
    { name: '30,001 to 40,000 TK', range: { min: 30001, max: 40000 } },
    { name: '40,001 to 50,000 TK', range: { min: 40001, max: 50000 } },
    { name: '50,001 to 60,000 TK', range: { min: 50001, max: 60000 } },
    { name: '60,001 to 70,000 TK', range: { min: 60001, max: 70000 } },
    { name: '70,001 to 80,000 TK', range: { min: 70001, max: 80000 } },
    { name: '80,001 to 90,000 TK', range: { min: 80001, max: 90000 } },
    { name: '90,001 to 1,00,000 TK', range: { min: 90001, max: 100000 } },
    { name: 'Above 1,00,000 TK', range: { min: 100001, max: 1000000 } },
  ],
  ram: [
    { name: '1 GB' },
    { name: '1.5 GB' },
    { name: '2 GB' },
    { name: '3 GB' },
    { name: '4 GB' },
    { name: '6 GB' },
    { name: '8 GB' },
    { name: '12 GB' },
    { name: '16 GB' },
    { name: '18 GB' },
    { name: '20 GB' },
    { name: '265 GB' },
    { name: '512 GB' },
  ],
  sensors: [
    { name: 'Accelerometer' },
    { name: 'Brometer' },
    { name: 'Compass' },
    { name: 'Fingerprint' },
    { name: 'Gyro' },
    { name: 'Heart Rate' },
    { name: 'Humidity' },
    { name: 'Proximity' },
    { name: 'Temperature' },
  ],
  sim: [
    { name: 'Single SIM' },
    { name: 'Dual SIM' },
    { name: 'Quad SIM' },
    { name: 'Triple SIM' },
  ],
  storage: [
    { name: '1 GB' },
    { name: '2 GB' },
    { name: '4 GB' },
    { name: '8 GB' },
    { name: '12 GB' },
    { name: '16 GB' },
    { name: '32 GB' },
    { name: '64 GB' },
    { name: '128 GB' },
    { name: '265 GB' },
    { name: '512 GB' },
    { name: '1 TB' },
  ],
};
const emptyState = {
  battery: [],
  camera: [],
  connectivity: [],
  cpu: [],
  display: [],
  network: [],
  os: [],
  price: [],
  ram: [],
  sensors: [],
  sim: [],
  storage: [],
};
export default function IndexView() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(filterExampleData);

  const { currentUser } = useAuth();
  const resetData = (res) => {
    setData({
      battery: res.battery ? res.battery : [],
      camera: res.camera ? res.camera : [],
      connectivity: res.connectivity ? res.connectivity : [],
      cpu: res.cpu ? res.cpu : [],
      display: res.display ? res.display : [],
      network: res.network ? res.network : [],
      os: res.os ? res.os : [],
      price: res.price ? res.price : [],
      ram: res.ram ? res.ram : [],
      sensors: res.sensors ? res.sensors : [],
      sim: res.sim ? res.sim : [],
      storage: res.storage ? res.storage : [],
    });
  };
  function isArray(value) {
    return value instanceof Array;
  }

  const fetch = () => {
    axios.get(`${BASE_URL}/api/base/filters/get`).then((res) => {
      if (res.data.success) {
        console.log(res.data.filters);
        if (!isArray(res.data.filters)) resetData(res.data.filters);
        else setData(emptyState);
      } else {
        alert(
          'Contanct your developer. Because you database has no navbar information.'
        );
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    fetch();
  }, [currentUser.userId]);

  const storeFilter = (data) => {
    axios
      .post(`${BASE_URL}/api/base/filters/set`, { filters: data })
      .then((res) => {
        if (res.data.success) {
        } else {
          alert('Can not update nav status. please contact your developer.');
        }
      });
  };

  const handleChange = (type, index, checked) => {
    let d = { ...data };
    d[type].pop(index);
    storeFilter(d);
    setData(d);
  };

  const addNew = (type, input) => {
    let d = { ...data };
    d[type].push(input);
    storeFilter(d);
    setData(d);
  };

  return (
    <ReadContainer>
      <div className="dark-text">
        <SubmitLoading open={loading} />
        <Title className="dark-text">Battery</Title>
        {data.battery?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('battery', i, e.target.checked)
          );
        })}

        <RangeInput
          label="Add battery"
          cb={(d) => {
            addNew('battery', d);
          }}
          suffix=" mAh"
        />
        {Gap()}
        
        <Title className="dark-text">Camera</Title>
        {data.camera?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('camera', i, e.target.checked)
          );
        })}
        <br />
        <br />
        <SingleInput
          label="Add camera"
          cb={(d) => {
            addNew('camera', d);
          }}
          suffix="MP"
          onlyNumber={true}
          float={true}
        />
        {Gap()}
        <Title className="dark-text">Connectivity</Title>

        {data.connectivity?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('connectivity', i, e.target.checked)
          );
        })}
        <br />
        <br />
        <SingleInput
          label="Add connectivity"
          cb={(d) => {
            addNew('connectivity', d);
          }}
        />
        {Gap()}
        <Title className="dark-text">CPU</Title>
        {data.cpu?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('cpu', i, e.target.checked)
          );
        })}

        <br />
        <br />
        <RangeInput
          label="Add CPU"
          cb={(d) => {
            addNew('cpu', d);
          }}
          suffix=" GHz"
          float={true}
        />
        {Gap()}
        <Title className="dark-text">Display</Title>
        {data.display?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('display', i, e.target.checked)
          );
        })}
        <br />
        <br />
        <RangeInput
          label="Add Display"
          cb={(d) => {
            addNew('display', d);
          }}
          suffix=" inches"
          float={true}
        />
        {Gap()}
        <Title className="dark-text">Network</Title>
        {data.network?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('network', i, e.target.checked)
          );
        })}
        <br />
        <br />
        <SingleInput
          label="Add Network"
          cb={(d) => {
            addNew('network', d);
          }}
          suffix="G"
          onlyNumber={true}
        />
        {Gap()}
        <Title className="dark-text">OS</Title>
        {data.os?.map((val, i) => {
          return CheckView(val, (e) => handleChange('os', i, e.target.checked));
        })}
        <br />
        <br />
        <SingleInput
          label="Add OS"
          cb={(d) => {
            addNew('os', d);
          }}
        />
        {Gap()}
        <Title className="dark-text">Price</Title>
        {data.price?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('price', i, e.target.checked)
          );
        })}

        <br />
        <br />
        <RangeInput
          label="Add Price"
          cb={(d) => {
            let val = {
              name: `${MoneyFormatInt(d.range.min)} to ${MoneyFormatInt(
                d.range.max
              )} TK`,
              range: d.range,
            };

            addNew('price', val);
          }}
        />
        {Gap()}
        <Title className="dark-text">RAM</Title>
        {data.ram?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('ram', i, e.target.checked)
          );
        })}
        <br />
        <br />
        <SingleInput
          label="Add RAM"
          cb={(d) => {
            addNew('ram', d);
          }}
          suffix=" GB"
          onlyNumber={true}
        />
        {Gap()}
        <Title className="dark-text">Sensors</Title>
        {data.sensors?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('sensors', i, e.target.checked)
          );
        })}
        <br />
        <br />
        <SingleInput
          label="Add Sensors"
          cb={(d) => {
            addNew('sensors', d);
          }}
        />
        {Gap()}
        <Title className="dark-text">SIM</Title>
        {data.sim?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('sim', i, e.target.checked)
          );
        })}
        <br />
        <br />
        <SingleInput
          label="Add SIM"
          cb={(d) => {
            addNew('sim', d);
          }}
        />
        {Gap()}
        <Title className="dark-text">Storage</Title>
        {data.storage?.map((val, i) => {
          return CheckView(val, (e) =>
            handleChange('storage', i, e.target.checked)
          );
        })}
        <br />
        <br />
        <SingleInput
          label="Add Storage"
          cb={(d) => {
            addNew('storage', d);
          }}
          suffix=" GB"
          onlyNumber={true}
        />
        {Gap()}
      </div>
    </ReadContainer>
  );
}
