import React, { forwardRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { YOUR_DOMAIN } from '../../config';

const Link = Quill.import('formats/link');

class MyLink extends Link {
  static create(value) {
    let node = Link.create(value);
    value = Link.sanitize(value);
    node.setAttribute('href', value);
    if (value.startsWith(YOUR_DOMAIN)) {
      node.removeAttribute('target');
      node.removeAttribute('rel');
    } else {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
    return node;
  }

  format(name, value) {
    super.format(name, value);
    if (name !== this.statics.blotName || !value) {
      return;
    }

    if (value.startsWith(YOUR_DOMAIN)) {
      this.domNode.removeAttribute('target');
      this.domNode.removeAttribute('rel');
    } else {
      this.domNode.setAttribute('target', '_blank');
      this.domNode.setAttribute('rel', 'noopener');
    }
  }
}
Quill.register('formats/link', MyLink);

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    // VVI ***
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const AdvancedEditor = forwardRef((props, ref) => {
  return (
    <div style={{ maxWidth: '100%', backgroundColor: 'white' }}>
      <ReactQuill
        ref={ref}
        theme={'snow'}
        defaultValue={props.value}
        modules={modules}
      />
    </div>
  );
});
export default AdvancedEditor;
