import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { BpIcon, BpCheckedIcon } from './BpCheckbox';

export default function CheckView(val, cb) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={{
            '&:hover': { bgcolor: 'transparent' },
          }}
          style={{ color: 'black' }}
          disableRipple
          color="default"
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
          inputProps={{ 'aria-label': 'Checkbox demo' }}
          checked={val.checked}
          onChange={cb}
        />
      }
      label={val.name}
    />
  );
}
