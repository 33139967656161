import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import InputHeading from '../InputHeading';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '../InputLabel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Dropzone from 'react-dropzone';
import useAuth from '../../contexts/AuthContext';
import SearchField from './SearchField';
import { TextArea } from './components';
import ItemChoose from '../rd/ItemChoose';
import { CDN_URL, BASE_URL } from '../../config';
import './UploadProduct.css';
import { ReadContainer600 } from '../share';
import SearchPost from '../SearchPost';

export default function ({ edit, handleClose }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [selected, setSelected] = useState([]);
  const [posts, setPosts] = useState([]);

  const [data, setData] = useState({
    title: '',
    image: '',
  });

  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });

  const [loading, setLoading] = useState(false);

  const submitPost = (e) => {
    e.preventDefault();

    // if (currentUser && !currentUser.isAuth) {
    //   return alert('Please log in first.');
    // }
    let items = selected.map((val) => val.url);

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });
    axios
      .post(`${BASE_URL}/api/comparisons/add`, {
        ...data,
        title: getTitle(selected),
        items: items,
      })
      .then((response) => {
        if (response.data.success) {
          // alert("Uploaded successfully!");
          navigate('/comparisons');
        } else {
          alert('Failed while uploading your post. Please try again later.');
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        alert(
          response.err == undefined ? 'Internal Server Error' : response.err
        );
        setLoading(false);
      });
  };

  const getTitle = (arr) => {
    let vs = '';
    arr.map((val, i) => {
      let add = ' vs ';
      if (i === arr.length - 1) add = '';
      vs += val.name + add;
    });
    return vs;
  };

  const selectPhoto = (files) => {
    if (!files[0]) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    axios
      .post(`${CDN_URL}/api/user/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData({ ...data, image: res.data.filename });
        }
      });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Add Comparison"
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/comparisons"
      />
      <h2>{getTitle(selected)}</h2>
      <div style={{ marginTop: '18px' }}>
        <div>
          <Dropzone
            className="video-drop-zone"
            onDrop={selectPhoto}
            multiple={false}
            maxSize={800000000}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className="hover-lightgrey"
                style={{
                  width: '130px',
                  height: '120px',
                  display: 'flex',
                  border: '.5px dashed lightgray',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />

                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  {data.image ? (
                    <img
                      style={{ width: '100%' }}
                      alt="thumbnail"
                      src={`${CDN_URL}/cdn/photo/${data.image}`}
                    />
                  ) : (
                    <div>
                      {uploadStatus.isUploading && (
                        <CircularProgress
                          disableShrink
                          size="24px"
                          style={{ color: 'lightgrey' }}
                        />
                      )}
                      {uploadStatus.isUploading && (
                        <p style={{ color: 'grey', fontSize: '12px' }}>
                          {`${uploadStatus.uploadPercentage}%`}
                        </p>
                      )}

                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            fontSize: '12px',
                            display: 'flex',
                            flexDirection: 'row',
                            fontWeight: 'bold',
                            color: 'rgb(99, 91, 255)',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <ArrowUpwardIcon
                            style={{ fontSize: '16px', marginRight: '2px' }}
                          />
                          <p style={{ margin: '0' }}>Image</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
      
      {selected?.map((val) => {
        return (
          <ItemChoose
            data={val}
            cb={() => {
              setSelected(selected.filter((item) => item._id != val._id));
            }}
          />
        );
      })}
      <br />
      <hr />
      <br />

      <SearchPost
        cb={(posts) => {
          setPosts(posts);
        }}
      />
      <br />
      <br />
      {posts?.map((val) => {
        return (
          <ItemChoose
            data={val}
            cb={() => {
              setSelected((oldArray) => [...oldArray, val]);
            }}
          />
        );
      })}
      {loading && <CircularProgress />}
      <br />
    </ReadContainer600>
  );
}
