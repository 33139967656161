import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SubmitLoading from '../components/SubmitLoading';
import { ReadContainer } from '../components/share';
import { CLIENT_URL } from '../config';
import './style.css';

const Card = styled.div`
  padding: 8px;
  width: 100%;
  background-color: white;
  margin-bottom: 8px;
  border: 1px solid transparent;
`;

export default function IndexView() {
  const [refresh, setRefresh] = useState({});
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState({
    sitemap: false,
    rssfeed: false,
  });

  const sitemapGenerate = () => {
    setProcessing({ ...processing, sitemap: true });
    axios
      .get(`${CLIENT_URL}/api/gsitemap`)
      .then((res) => {
        if (res.data.success) {
          alert('Sitemap generated successfully!');
        } else {
          alert('Something wrong try again later!');
        }
        setProcessing({ ...processing, sitemap: false });
        setRefresh({ ...refresh, sitemap: true });
      })
      .catch((err) => {
        console.log(err);
        alert('Something wrong try again later!');
        setProcessing({ ...processing, sitemap: false });
      });
  };

  const rssFeedGenerate = () => {
    setProcessing({ ...processing, rssfeed: true });
    axios
      .get(`${CLIENT_URL}/api/grssfeed`)
      .then((res) => {
        if (res.data.success) {
          alert('Sitemap generated successfully!');
        } else {
          alert('Something wrong try again later!');
        }
        setProcessing({ ...processing, rssfeed: false });
        setRefresh({ ...refresh, rssfeed: true });
      })
      .catch((err) => {
        console.log(err);
        alert('Something wrong try again later!');
        setProcessing({ ...processing, rssfeed: false });
      });
  };

  return (
    <ReadContainer>
      <div className="dark-text">
        <h2>Settings</h2>
        <hr />
        <SubmitLoading open={loading} />
        <Card>
          <h3 className="dark-text">Sitemap</h3>
          {refresh.sitemap ? (
            <Button variant="contained" disableElevation disabled={true}>
              <CheckCircleIcon color="success" />
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={sitemapGenerate}
              disableElevation
              startIcon={processing.sitemap ? null : <AutorenewIcon />}
              disabled={processing.sitemap}
            >
              {processing.sitemap ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Renew'
              )}
            </Button>
          )}
        </Card>
        <Card>
          <h3 className="dark-text">RSS Feed</h3>
          {refresh.rssfeed ? (
            <Button variant="contained" disableElevation disabled={true}>
              <CheckCircleIcon color="success" />
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={rssFeedGenerate}
              disableElevation
              startIcon={processing.rssfeed ? null : <AutorenewIcon />}
              disabled={processing.rssfeed}
            >
              {processing.rssfeed ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Renew'
              )}
            </Button>
          )}
        </Card>
      </div>
    </ReadContainer>
  );
}
