import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useParams } from 'react-router';
import InputHeading from '../InputHeading';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '../InputLabel';
import SpecDataInput from './SpecDataInput';

import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Dropzone from 'react-dropzone';
import useAuth from '../../contexts/AuthContext';
import { ReadContainer } from '../share';
import { RemoveItemBtn, UploadedItem } from './DesignPortfolioInput';

import './UploadProduct.css';
import { CDN_URL, BASE_URL } from '../../config';
import PostCatgDropdown from '../PostCatgDropdown';
import ProsConsInput from './ProsConsInput';
import AffiliateInput from './AffiliateInput';
import UrlInput from './UrlInput';
import AdvancedEditor from './AdvancedEditor';

export default function UploadPost({ edit, url, scrappedData, toolbarHide }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const [htmlContent, setHtmlContent] = useState(''); // empty is important here
  const inputRef = useRef(null);
  const [data, setData] = useState(
    scrappedData
      ? scrappedData
      : {
          url: '',
          title: '',
          name: '',
          released: '',
          variant: '',
          desc: '',
          htmlDesc: '<p></p>',
          metaTitle: '',
          metaDesc: '',
          price: '',
          brand: '',
          released: '',
          os: '',
          display: '',
          camera: '',
          ram: '',
          battery: '',
          category: 'Smartphone',
          category_path: 'smartphone',
          discount_price: '',
          overview: '',
          meta_desc: '',
          affiliates: [
            {
              affiliateId: '',
              productId: '',
              link: '',
              price: '',
              vendorImg: '',
              vendorName: '',
            },
          ],
          disclaimer:
            'Disclaimer: We do not guarantee that the information of this page is 100% accurate and up to date.',
          specs: [{ name: '', values: [{ name: '', values: '' }] }],
          proscons: [{ pros: '', cons: '' }],
        }
  );

  const [filters, setFilters] = useState({});
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    postUploading: false,
    progressbar: false,
  });
  const [uploadedFiles, setUploadedFiles] = useState(
    scrappedData && scrappedData.images ? scrappedData.images : []
  );

  const [primaryImage, setPrimary] = useState(0); // TODO: ui to select main image

  useEffect(() => {
    // get query _id
    // fetch
    // assign values as post
    // value set should be universal for both new and edit time
    if (scrappedData) {
      setFilters(scrappedData.filters);
      setHtmlContent(scrappedData.desc || ' ');
    } else {
      if (edit) {
        console.log(`${BASE_URL}/api/posts/${url ? 'edit' : 'editId'}/${id}`);
        axios
          .get(`${BASE_URL}/api/posts/${url ? 'edit' : 'editId'}/${id}`)
          .then((res) => {
            console.log(res.data);
            if (res.data.success) {
              setFilters(res.data.data.filters);
              setData(res.data.data);
              setHtmlContent(res.data.data.htmlDesc || ' ');
              setUploadedFiles(res.data.data.media);
            }
          });
      } else {
        let filters = {};
        filters.battery = '';
        filters.camera = '';
        filters.connectivity = '';
        filters.cpu = '';
        filters.display = '';
        filters.network = '';
        filters.os = '';
        filters.price = '';
        filters.ram = '';
        filters.sensors = '';
        filters.sim = '';
        filters.storage = '';
        setFilters(filters);
        setHtmlContent(' ');
      }
    }
  }, [edit, scrappedData]);

  const round = (number, decimalPlaces) => {
    const factorOfTen = Math.pow(10, decimalPlaces);
    return Math.round(number * factorOfTen) / factorOfTen;
  };

  const addInput = (file) => {
    if (!file) return;
    let formData = new FormData();
    formData.append('file', file);

    setUploadState({ ...uploadStatus, fileUploading: true });
    const config = {
      header: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        setUploadState({
          ...uploadStatus,
          uploadPercentage: round((progressEvent / 1) * 100, 2),
          progressbar: true,
        });
      },
    };
    axios
      .post(`${CDN_URL}/api/post/media`, formData, config)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data);
          // setUploadedFiles((files) => [...files, res.data]);
          setUploadedFiles((files) => [...files, res.data]);
        } else {
          alert('failed to save the files');
        }
        setUploadState({
          ...uploadStatus,
          uploadPercentage: 0,
          uploadCompleted: true,
          isUploading: false,
        });
        setUploadState({ ...uploadStatus, fileUploading: false });
      })
      .catch((err) => {
        setUploadState({ ...uploadStatus, fileUploading: false });
      });
  };

  const handleCategory = (e) => {
    // checkUrl(e.target.value.toLowerCase(), data.url);
    // changeDisplayUrl(e.target.value, data.url);
    setData({
      ...data,
      category: e.target.value,
      category_path: e.target.value.trim().toLowerCase().replace(' ', '-'),
    });
  };

  const submitPost = (e) => {
    e.preventDefault();
    if (currentUser && !currentUser.isAuth) {
      return alert('Please log in first.');
    }
    if (uploadedFiles.length === 0) {
      return alert('Upload a photo/video first.');
    }
    setUploadState({ ...uploadStatus, postUploading: true });
    axios
      .post(
        edit ? `${BASE_URL}/api/posts/update` : `${BASE_URL}/api/posts/create`,
        {
          ...data,
          filters: filters,
          // userId: currentUser.userId,
          files: uploadedFiles,
          image_name: uploadedFiles[primaryImage]?.filename,
          keyword: data.keyword?.trim(),
          keywords: data.keyword
            ?.trim()
            .split(',')
            .map((val) => val.trim()),
          brandId: data.brand?.trim().toLowerCase().replace(' ', '-'),
          htmlDesc: inputRef.current?.value,
          desc: inputRef.current?.editor.getText(),
        }
      )
      .then((res) => {
        console.log(res.data.data);
        if (res.data.success) {
          alert('Post uploaded successfully!');
          navigate('/');
        } else {
          alert('Failed while uploading your post. Please try again later.');
          setUploadState({ ...uploadStatus, postUploading: false });
        }
      })
      .catch(({ response }) => {
        console.log(response);
        setUploadState({ ...uploadStatus, postUploading: false });
        alert(
          response.err == undefined ? 'Internal Server Error' : response.err
        );
      });
  };

  const onDrop = (files) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      addInput(file);
    }
  };

  const specsValuesChanged = (specs_index, val_index, o) => {
    const specs = data.specs;
    if (specs[specs_index].values.length <= val_index)
      specs[specs_index].values.push(o);
    else specs[specs_index].values[val_index] = o;
    setData({ ...data, specs: specs });
  };

  return (
    <ReadContainer>
      {!toolbarHide && (
        <InputHeading
          title="New Post"
          btnTxt="Submit"
          submit={submitPost}
          loading={
            uploadStatus.fileUploading ||
            uploadStatus.postUploading ||
            uploadStatus.isUploading
          }
          // back="/post"
        />
      )}
      <form onSubmit={submitPost} className="upload-moadal">
        <div className="grid-layout">
          {uploadedFiles.map((val, index) => (
            <UploadedItem key={val.filename}>
              {val.type === 'video' ? (
                <video
                  key={`${index}-${val.filename}`}
                  autoPlay={true}
                  loop={true}
                  muted
                  style={{ width: '100%' }}
                >
                  <source
                    src={`${CDN_URL}/cdn/video/${val.filename}`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  key={`${index}-${val.filename}`}
                  style={{ width: '100%' }}
                  alt="thumbnail"
                  src={`${CDN_URL}/cdn/small/${val.thumbnail}`}
                />
              )}
              <RemoveItemBtn
                onClick={() => {
                  axios
                    .post(`${CDN_URL}/api/upload/deletefile`, val)
                    .then((res) => {
                      console.log(res.data);
                      if (res.data.success) {
                        setUploadedFiles(
                          uploadedFiles.filter((_, i) => i !== index)
                        );
                      } else alert(res.data);
                    })
                    .catch((e) => {
                      alert(e);
                    });
                }}
              >
                <CloseIcon />
              </RemoveItemBtn>
            </UploadedItem>
          ))}
          <div>
            <Dropzone
              className="video-drop-zone"
              onDrop={onDrop}
              multiple={true}
              maxSize={800000000}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="hover-lightgrey"
                  style={{
                    width: '130px',
                    height: '120px',
                    display: 'flex',
                    border: '.5px dashed lightgray',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />

                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {uploadStatus.isUploading && (
                      <CircularProgress
                        disableShrink
                        size="24px"
                        style={{ color: 'lightgrey' }}
                      />
                    )}
                    {uploadStatus.isUploading && (
                      <p style={{ color: 'grey', fontSize: '12px' }}>
                        `${uploadStatus.uploadPercentage}%`
                      </p>
                    )}

                    {!uploadStatus.isUploading && (
                      <div
                        style={{
                          fontSize: '12px',
                          display: 'flex',
                          flexDirection: 'row',
                          fontWeight: 'bold',
                          color: 'rgb(99, 91, 255)',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <ArrowUpwardIcon
                          style={{ fontSize: '16px', marginRight: '2px' }}
                        />
                        <p style={{ margin: '0' }}>Upload</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>

        <br />
        <br />
        <PostCatgDropdown cb={handleCategory} val={data.category} />
        <br />
        <div>
          <InputLabel label="Title" />
          <textarea
            id="title"
            label="title"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            rows="1"
            onChange={(e) => {
              setData({ ...data, title: e.currentTarget.value });
            }}
            value={data.title}
          />
          <InputLabel label="Name" />
          <textarea
            id="name"
            label="name"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            rows="1"
            onChange={(e) => {
              setData({ ...data, name: e.currentTarget.value });
            }}
            value={data.name}
          />
          <UrlInput
            route="posts"
            state={data.url}
            cb={(d) => {
              setData({ ...data, url: d });
            }}
          />

          <InputLabel label="Variant" />
          <input
            label="variant"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setData({ ...data, variant: e.currentTarget.value });
            }}
            value={data.variant}
          />

          <InputLabel label="Brand *" />
          {/* make it dropdown */}
          <input
            label="brand"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setData({ ...data, brand: e.currentTarget.value });
            }}
            value={data.brand}
          />
          <br />
          <br />
          <hr />
          <br />
          <InputLabel label="Released" />
          <input
            label="released"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setData({ ...data, released: e.currentTarget.value });
            }}
            value={data.released}
          />
          <InputLabel label="OS" />
          <input
            label="os"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setData({ ...data, os: e.currentTarget.value });
            }}
            value={data.os}
          />
          <InputLabel label="Display" />
          <input
            label="display"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setData({ ...data, display: e.currentTarget.value });
            }}
            value={data.display}
          />
          <InputLabel label="Camera" />
          <input
            label="camera"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setData({ ...data, camera: e.currentTarget.value });
            }}
            value={data.camera}
          />
          <InputLabel label="RAM" />
          <input
            label="ram"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setData({ ...data, ram: e.currentTarget.value });
            }}
            value={data.ram}
          />
          <InputLabel label="Battery" />
          <input
            label="battery"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setData({ ...data, battery: e.currentTarget.value });
            }}
            value={data.battery}
          />
          <br />
          <br />
          <hr />
          <br />
          <InputLabel label="Description" />
          {/* <textarea
            id="desc"
            label="desc"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              boxSizing: 'border-box',
              fontSize: '1rem',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            rows="4"
            onChange={(e) => {
              setData({ ...data, desc: e.currentTarget.value });
            }}
            value={data.desc}
          /> */}

          {/* To set of default value after fetch post*/}
          {edit && htmlContent && (
            <AdvancedEditor value={htmlContent} ref={inputRef} />
          )}
          {!edit && <AdvancedEditor value={htmlContent} ref={inputRef} />}
        </div>
        <div>
          <InputLabel label="Price *" />
          <textarea
            id="price"
            label="price"
            type="number"
            style={{
              padding: '4px 6px',
              width: '240px',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            rows="1"
            onChange={(e) => {
              // TODO: max 2 decimal
              setData({ ...data, price: e.currentTarget.value });
            }}
            value={data.price}
          />
          {/* <InputLabel label="Special price" />
          <textarea
            id="special-price"
            label="discount_price"
            type="number"
            style={{
              padding: "4px 6px",
              width: "240px",
              boxSizing: "border-box",
              textAlign: "start",
              fontSize: "1rem",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            rows="1"
            onChange={(e) => {
    setData({ ...data, discount_price: e.currentTarget.value });
  }}
            value={data.discount_price}
          /> */}
        </div>
        <br />
        <br />
        <hr />
        <br />
        <h3 className="input-section-title" style={{ textAlign: 'center' }}>
          Specifications
        </h3>
        <br />
        {data.specs?.map((val, i) => {
          return (
            <div className="spec-input-div">
              <div>
                <InputLabel label="Spec Title" />
                <textarea
                  id="price"
                  label="price"
                  style={{
                    padding: '4px 6px',
                    width: '240px',
                    textAlign: 'start',
                    fontSize: '1rem',
                    boxSizing: 'border-box',
                    fontFamily:
                      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                  }}
                  rows="1"
                  onChange={(e) => {
                    // set name to this specs
                    const specs = data.specs;
                    specs[i].name = e.target.value;
                    setData({ ...data, specs: specs });
                  }}
                  value={val.name}
                />
              </div>
              <SpecDataInput
                val={val}
                specs_index={i}
                onChange={specsValuesChanged}
              />
            </div>
          );
        })}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            size="small"
            onClick={() => {
              let s = data.specs;
              s.push({ name: '', values: [{ name: '', value: '' }] });
              setData({ ...data, specs: s });
            }}
          >
            +Add specification
          </Button>
        </div>
        <br />
        <br />
        <hr />
        <br />
        <InputLabel label="Model" />
        <input
          label="model"
          style={{
            padding: '4px 6px',
            width: '100%',
            textAlign: 'start',
            fontSize: '1rem',
            boxSizing: 'border-box',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          }}
          onChange={(e) => {
            setData({ ...data, model: e.currentTarget.value });
          }}
          value={data.model}
        />
        <InputLabel
          label="Keywords"
          tooltip="Use comma(,) to separate points."
        />
        <textarea
          id="key-points"
          label="overviews"
          style={{
            padding: '4px 6px',
            width: '360px',
            textAlign: 'start',
            fontSize: '1rem',
            boxSizing: 'border-box',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          }}
          rows="4"
          onChange={(e) => {
            setData({ ...data, keyword: e.currentTarget.value });
          }}
          value={data.keyword}
        />
        <br />
        <InputLabel label="Meta title" />
        <textarea
          id="metaTitle"
          label="metaTitle"
          placeholder="Meta title"
          style={{
            padding: '4px 6px',
            width: '100%',
            textAlign: 'start',
            boxSizing: 'border-box',
            fontSize: '1rem',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          }}
          rows="3"
          onChange={(e) => {
            setData({ ...data, metaTitle: e.currentTarget.value });
          }}
          value={data.metaTitle}
        />
        <InputLabel label="Meta description" />
        <textarea
          id="metaDesc"
          label="metaDesc"
          placeholder="Within 160-300 char"
          style={{
            padding: '4px 6px',
            width: '100%',
            textAlign: 'start',
            boxSizing: 'border-box',
            fontSize: '1rem',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          }}
          rows="3"
          onChange={(e) => {
            setData({ ...data, metaDesc: e.currentTarget.value });
          }}
          value={data.metaDesc}
        />
        <InputLabel label="Disclaimer" />
        <textarea
          label="disclaimer"
          style={{
            padding: '4px 6px',
            width: '100%',
            textAlign: 'start',
            fontSize: '1rem',
            boxSizing: 'border-box',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          }}
          rows="4"
          onChange={(e) => {
            setData({ ...data, disclaimer: e.currentTarget.value });
          }}
          value={data.disclaimer}
        />
        <br />
        <br />
        <ProsConsInput
          val={data.proscons}
          onChange={(d) => setData({ ...data, proscons: d })}
        />

        <br />
        <hr />
        <br />
        <div>
          <h2>SEARCH FILTER</h2>
          <InputLabel label="Battery" />
          <input
            label="battery"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, battery: e.currentTarget.value });
            }}
            value={filters.battery}
          />
          <InputLabel label="Camera" />
          <input
            label="camera"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, camera: e.currentTarget.value });
            }}
            value={filters.camera}
          />
          <InputLabel label="Connectivity" />
          <input
            label="connectivity"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, connectivity: e.currentTarget.value });
            }}
            value={filters.connectivity}
          />
          <InputLabel label="CPU" />
          <input
            label="cpu"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, cpu: e.currentTarget.value });
            }}
            value={filters.cpu}
          />
          <InputLabel label="Display" />
          <input
            label="display"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, display: e.currentTarget.value });
            }}
            value={filters.display}
          />
          <InputLabel label="Network" />
          <input
            label="network"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, network: e.currentTarget.value });
            }}
            value={filters.network}
          />

          <InputLabel label="OS" />
          <input
            label="os"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, os: e.currentTarget.value });
            }}
            value={filters.os}
          />
          <InputLabel label="Price *" />
          <input
            label="price"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, price: e.currentTarget.value });
            }}
            value={filters.price}
          />

          <InputLabel label="RAM" />
          <input
            label="ram"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, ram: e.currentTarget.value });
            }}
            value={filters.ram}
          />
          <InputLabel label="Sensors" />
          <input
            label="sensors"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, sensors: e.currentTarget.value });
            }}
            value={filters.sensors}
          />
          <InputLabel label="SIM" />
          <input
            label="sim"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, sim: e.currentTarget.value });
            }}
            value={filters.sim}
          />
          <InputLabel label="Storage" />
          <input
            label="storage"
            style={{
              padding: '4px 6px',
              width: '100%',
              textAlign: 'start',
              fontSize: '1rem',
              boxSizing: 'border-box',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            }}
            onChange={(e) => {
              setFilters({ ...filters, storage: e.currentTarget.value });
            }}
            value={filters.storage}
          />
        </div>
        <br />
        <hr />
        <br />
        <h2>AFFILIATE MARKETING</h2>
        <AffiliateInput
          srcData={data.affiliates}
          onChange={(d) => setData({ ...data, affiliates: d })}
        />
        <br />
        <br />
        <br />
      </form>
    </ReadContainer>
  );
}
