import React, { useState } from 'react';
import Input from '@mui/material/Input';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';

const SearchForm = styled.form`
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  width: 100%;
  height: 36px;
  min-width: 125px;

  .search-input {
    width: 100%;
    padding: 0 12px;
  }

  border: 0.6px solid grey;

  .search-end-btn {
    height: 100%;
    width: 58px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: black;
    :active {
      background-color: #005992;
    }
    cursor: pointer;
  }
  .search-start-icon {
    color: white;
    font-size: 24px;
  }
`;
export default function SearchField({ submit, onChange }) {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  function searchHandle(e) {
    e.preventDefault();
    if (submit) submit(search);
  }

  return (
    <SearchForm onSubmit={searchHandle}>
      <Input
        className="search-input"
        placeholder="Search"
        disableUnderline={true}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          if (onChange) onChange(e.target.value);
        }}
      />
      {search && (
        <CloseIcon
          onClick={() => {
            setSearch('');
            if (onChange) onChange('');
            if (submit) submit('');
          }}
          style={{ marginRight: '6px' }}
        />
      )}

      <div
        className="search-end-btn"
        onClick={() => {
          if (submit) submit(search);
        }}
      >
        <SearchIcon className="search-start-icon" />
      </div>
    </SearchForm>
  );
}
