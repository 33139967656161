import { UPDATE_USER_NAME } from "../actions/types";

const intialState = {
  name: "Tap Action",
  email: "jhon@gmail.com",
  like: 129,
};

export default function (state = intialState, action) {
  switch (action.type) {
    case UPDATE_USER_NAME:
      return {
        ...state,
        name: action.payload,
      };

    default:
      return state;
  }
}
