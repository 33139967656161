import React, { useState } from 'react';
import InputLabel from '../InputLabel';
import Button from '@mui/material/Button';

export default function SpecDataInput({ val, specs_index, onChange }) {
  const addField = () => {
    onChange(specs_index, val.values.length, { name: '', value: '' });
  };

  const changeFieldValue = (index, name, value) => {
    onChange(specs_index, index, { name: name, value: value });
  };
  return (
    <div className="spec-input-value-div">
      <InputLabel label="Spec Data" />
      {val.values.map((data, pos) => {
        return (
          <div className='spec-input-value-div-child'>
            <textarea
              placeholder="Name"
              style={{
                margin: '0 6px 0 0',
                padding: '4px 6px',
                width: '140px',
                textAlign: 'start',
                fontSize: '.875rem',
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              }}
              rows="3"
              onChange={(e) => {
                // todo:
                // data.name = e.currentTarget.value;
                changeFieldValue(pos, e.target.value, data.value);
              }}
              value={data.name}
            />
            <textarea
              id="value"
              placeholder="value"
              className="spec-input-value-div-val"
              rows="3"
              onChange={(e) => {
                // todo:
                // data.value = e.currentTarget.value;
                changeFieldValue(pos, data.name, e.target.value);
              }}
              value={data.value}
            />
          </div>
        );
      })}

      <Button
        variant="outlined"
        disableElevation
        size="small"
        onClick={addField}
      >
        + Add more data
      </Button>
    </div>
  );
}
