import React, { useEffect, useState } from 'react';
import InputLabel from '../InputLabel';
import { DOMAIN, BASE_URL } from '../../config';
import axios from 'axios';

export default function UrlInput({ state, route, cb }) {
  const [url, setUrl] = useState('');
  const [urlExist, setUrlExist] = useState(false);
  const [postUrlDisplay, setPostUrlDisplay] = useState(`${DOMAIN}/`);

  useEffect(() => {
    if (state) {
      setUrl(state);
      checkUrl(state);
    }
  }, [state, route]);

  const checkUrl = (url) => {
    if (route)
      axios
        .get(`${BASE_URL}/api/${route}/checkurl?url=${url}`)
        .then((res) => {
          if (res.data) setUrlExist(res.data.exist);
        })
        .catch((e) => {});
  };
  const changePostId = (event) => {
    setUrl(event.currentTarget.value);
    let v = event.currentTarget.value.toLowerCase().replaceAll(' ', '-'); // TODO: ' ' format
    checkUrl(v);
    setPostUrlDisplay(`${DOMAIN}/${v}`);
    cb(v);
  };

  return (
    <div>
      <InputLabel label="Url *" />
      {urlExist ? (
        <a target="_blank" rel="noreferrer" href={postUrlDisplay}>
          {postUrlDisplay}
        </a>
      ) : (
        <p>{postUrlDisplay}</p>
      )}
      {urlExist && (
        <p style={{ color: 'red' }}>The same url is already exist!</p>
      )}
      <textarea
        id="path"
        label="path"
        style={{
          padding: '4px 6px',
          boxSizing: 'border-box',
          width: '100%',
          textAlign: 'start',
          fontSize: '1rem',
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        }}
        rows="1"
        onChange={changePostId}
        value={url}
      />
    </div>
  );
}
