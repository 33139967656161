import { Button } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import { VirSpace3px } from './share';
import './NormalizeDashboard.css';

const Container = styled.div`
  h3 {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
  p {
    margin: 0;
    margin-top: 6px;
    padding: 0;
    line-height: 1.4;
  }

  @media screen and (max-width: 892px) {
    text-align: center;
    h3 {
      font-size: 16px;
    }
    a {
      font-size: 14px;
    }
  }
`;
const BtnDiv = styled.div`
  @media screen and (max-width: 892px) {
    display: flex;
    justify-content: center;
  }
  .btn1-margin {
    margin-right: 4px;
  }
`;
export default function NormalizeView({
  title,
  image,
  text,
  url,
  onDelete,
  onEdit,
}) {
  let str = text;
  if (str?.length > 240) {
    if (typeof str === 'string' || str instanceof String) {
      str = str.substring(0, 220) + '...';
    }
  }
  return (
    <>
      <tr className="desktop-table">
        {image && (
          <td align="right" className="center-align">
            <img style={{ width: '50px', height: '50px' }} src={image} />
          </td>
        )}
        <td
          component="th"
          scope="row"
          // className={row.isHead ? 'head' : 'body'}
        >
          <Container>
            <h3 className="dark-text">{title}</h3>
            {url && (
              <a
                target="_blank"
                rel="noreferrer"
                href={url}
                style={{ textDecoration: 'none' }}
              >
                {url}
              </a>
            )}
            <p className="price-color">৳&nbsp;{str}</p>
          </Container>
        </td>
        <td align="right">
          <BtnDiv>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={onDelete}
              style={{ width: '60px' }}
              className="btn1-margin"
            >
              Delete
            </Button>
            <VirSpace3px />

            {onEdit && (
              <Button
                variant="contained"
                disableElevation
                size="small"
                onClick={onEdit}
                style={{ width: '60px' }}
              >
                Edit
              </Button>
            )}
          </BtnDiv>
        </td>
      </tr>
      <tr className="phone-table">
        <Container>
        {image && <img style={{ width: '50px', height: '50px' }} src={image} />}
          <h3 className="dark-text">{title}</h3>
          {url && (
            <a
              target="_blank"
              rel="noreferrer"
              href={url}
              style={{ textDecoration: 'none' }}
            >
              {url}
            </a>
          )}
          <p className="price-color">৳&nbsp;{str}</p>
          <BtnDiv>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={onDelete}
              style={{ width: '60px' }}
              className="btn1-margin"
            >
              Delete
            </Button>
            <VirSpace3px />

            {onEdit && (
              <Button
                variant="outlined"
                size="small"
                onClick={onEdit}
                style={{ width: '60px' }}
              >
                Edit
              </Button>
            )}
          </BtnDiv>
        </Container>
      </tr>
    </>
  );
}
