import styled from "@emotion/styled";
export const TextArea = styled.textarea`
  padding: 4px 6px;
  width: 100%;
  box-sizing: border-box;
  text-align: start;
  font-size: 1rem;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
`;
export const TextAreaWidth = styled.textarea`
  padding: 4px 6px;
  width: 100%;
  max-width: 320px;
  margin: 2px;
  box-sizing: border-box;
  text-align: start;
  font-size: 1rem;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
`;
export const Input = styled.input`
  padding: 4px 6px;
  width: 100%;
  box-sizing: border-box;
  text-align: start;
  font-size: 1rem;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
`;
export const InputWidth = styled.input`
  padding: 4px 6px;
  width: 100%;
  max-width: 320px;
  margin: 2px;
  box-sizing: border-box;
  text-align: start;
  font-size: 1rem;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
`;
export const TextAreaValue = styled.textarea`
  padding: 4px 6px;
  width: 200px;
  text-align: start;
  font-size: 1rem;
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
`;
