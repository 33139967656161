import React from "react";
import styled from "styled-components";
import { CDN_URL, CLIENT_URL } from "../config";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { IconButton } from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ExternalLink from "./ExternalLink";

const Wrapper = styled.div`
  .mobile-brand-div {
    display: none;
  }
  @media (max-width: 640px) {
    .desktop-brand-div {
      display: none;
    }
    .mobile-brand-div {
      display: block;
    }
  }
`;

const DeleteContainer = styled.div`
  height: 28px;
  width: 28px;
  svg {
    height: 20px;
    width: 20px;
  }
  :hover {
    svg {
      color: red;
    }
  }
`;
const EditContainer = styled.div`
  height: 28px;
  width: 28px;
  svg {
    height: 20px;
    width: 20px;
  }
  :hover {
    svg {
      color: black;
    }
  }
`;
const ActionWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
`;

const BrandItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  img {
    height: 48px;
    width: 48px;
  }
`;

const PaymentOption = styled.div`
  border: 0.5px solid lightgrey;
  padding: 1px 0px 1px 16px;
  cursor: pointer;
  margin: 6px 0;
  :hover {
    border: 0.5px solid #05cfbc;
  }
`;
const BrandItemContainerInline = styled.div`
  position: relative;
  display: inline-flex;

  padding-left: 14px;
  padding-top: 14px;
  padding-right: 32px;
  margin-bottom: 48px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .delete-icon {
    position: absolute;
    top: 0;
    right: 0;
    :hover {
      svg {
        color: red;
      }
    }
  }
  img {
    width: 48px;
  }

  p {
    margin: 6px 0 0;
    padding: 0;
    color: rgb(5, 17, 69);
    font-size: 20px;
  }
  svg {
    color: grey;
  }
`;
export default function BrandList({ dataGroup, data, onEdit, onDelete }) {
  const getDesktopView = (obj) => {
    const arr = [];
    Object.keys(dataGroup).forEach((key) => {
      const alphabet = key.toString().toUpperCase();
      console.log(alphabet);
      arr.push(
        <div
          style={{
            backgroundColor: "#f2f2f2",
            border: "1px solid lightgrey",
            margin: "16px 0 20px",
          }}
        >
          <h2
            style={{
              fontSize: "24px",
              color: "#051145",
              padding: "8px 16px",
              margin: 0,
            }}
          >
            {alphabet}
          </h2>
          <PaymentOption
            style={{
              backgroundColor: "white",
              border: "none",
              margin: 0,
              padding: "16px",
            }}
          >
            {dataGroup[key].map((item) => {
              return (
                <ExternalLink
                  href={`${CLIENT_URL}/brand/${item.id}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <BrandItemContainerInline>
                    <div className="content">
                      <img
                        src={
                          item.logo ? `${CDN_URL}/cdn/brand/${item.logo}` : ""
                        }
                      />
                      <p>{item.name}</p>
                    </div>
                    <ActionWrapper>
                      <EditContainer>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (onEdit) onEdit(item.id);
                          }}
                        >
                          <EditRoundedIcon />
                        </IconButton>
                      </EditContainer>
                      <DeleteContainer>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (onDelete) onDelete(item._id);
                          }}
                        >
                          <HighlightOffRoundedIcon />
                        </IconButton>
                      </DeleteContainer>
                    </ActionWrapper>
                  </BrandItemContainerInline>
                </ExternalLink>
              );
            })}
          </PaymentOption>
        </div>
      );
    });

    return arr;
  };
  return (
    <Wrapper>
      <div className="desktop-brand-div">{getDesktopView(dataGroup)}</div>
      <div className="mobile-brand-div">
        {data.map((val) => (
          <ExternalLink
            href={`${CLIENT_URL}/brand/${val.id}`}
            style={{ textDecoration: "none", color: "black" }}
          >
            <PaymentOption>
              <BrandItemContainer>
                <img src={val.logo ? `${CDN_URL}/cdn/brand/${val.logo}` : ""} />
                <h1
                  style={{
                    fontSize: "30px",
                    color: "#051145",
                  }}
                >
                  {val.name}
                </h1>
                <ActionWrapper>
                  <EditContainer>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (onEdit) onEdit(val.id);
                      }}
                    >
                      <EditRoundedIcon />
                    </IconButton>
                  </EditContainer>
                  <DeleteContainer>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (onDelete) onDelete(val._id);
                      }}
                    >
                      <HighlightOffRoundedIcon />
                    </IconButton>
                  </DeleteContainer>
                </ActionWrapper>
              </BrandItemContainer>
            </PaymentOption>
          </ExternalLink>
        ))}
      </div>
    </Wrapper>
  );
}
