import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import InputHeading from '../InputHeading';
import InputLabel from '../InputLabel';
import useAuth from '../../contexts/AuthContext';
import { ReadContainer } from '../share';
import gsmarena from '../../assets/gsmarena.png';
// import gsmarenabd from '../../assets/gsmarenabd.jpg';
import mobiledokan from '../../assets/mobiledokan.jpg';
import mobiledokanco from '../../assets/mobiledokan.webp';
import ryans from '../../assets/ryans.jpg';
import { TextArea } from './components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  GSMARENA,
  GSMARENABD,
  MOBILEDOKAN,
  MOBILEDOKANCO,
  DJANGO_SERVER_SCRAP,
  CDN_URL,
} from '../../config';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { gsmarenaFilter, mobiledokancoFilter } from './filterdata';
import PostInput from './PostInput';
import './UploadProduct.css';
import PostCatgDropdown from '../PostCatgDropdown';

export default function ({ edit, handleClose }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [scrappedData, setScrappedData] = useState({});
  const [formatedData, setFormatedData] = useState({});
  const [data, setData] = useState({
    scrap: [
      {
        type: GSMARENA,
        siteName: 'gsmarena',
        // url: 'https://m.gsmarena.com/samsung_galaxy_s8-8161.php',
        // url: 'https://www.gsmarena.com/samsung_galaxy_s22_5g-11253.php',
        url: '',
        uploadStatus: 'INIT',
        imgUploadStatus: 'INIT',
      },
      // !important you have to keep this pos
      {
        type: GSMARENABD,
        siteName: 'gsmarena.com.bd',
        // url: 'https://www.gsmarena.com.bd/samsung-galaxy-s8-plus/',
        url: '',
        uploadStatus: 'INIT',
        imgUploadStatus: 'INIT',
      },
      {
        type: MOBILEDOKAN,
        siteName: 'mobiledokan',
        // url: "https://www.mobiledokan.co/product/samsung-galaxy-s8/",
        // url: 'https://www.mobiledokan.com/walton/walton-primo-s8-mini/',
        // url: 'https://www.mobiledokan.com/samsung/samsung-galaxy-s22-ultra/',
        url: '',
        uploadStatus: 'INIT',
        imgUploadStatus: 'INIT',
      },
      {
        type: MOBILEDOKANCO,
        siteName: 'mobiledokanco',
        // url: 'https://www.mobiledokan.co/product/walton-primo-s8-mini/',
        // url: 'https://www.mobiledokan.co/product/samsung-galaxy-s22-ultra-5g/',
        url: '',
        uploadStatus: 'INIT',
        imgUploadStatus: 'INIT',
      },
      // {
      //   type: "ryanscomputers",
      //   siteName: "ryanscomputer",
      //   url: "https://www.ryanscomputers.com/dell-optiplex-3280-10th-gen-intel-core-i3-10100t-21.5-inch-fhd-display-black-all-in-one-pc",
      //   uploadStatus: "INIT",
      //   imgUploadStatus: "INIT",
      // },
    ],
  });
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState({
    gsmarena: false,
    // gsmarenabd: false,
    mobiledokan: false,
    mobiledokanco: false,
    imgDownloading: false,
  });
  // console.log(loading);
  const changeUrl = (val, type) => {
    const scrap = data.scrap;
    let _pos = 0;
    for (let i = 0; i < data.scrap.length; i++) {
      if (data.scrap[i].type === type) {
        _pos = i;
        break;
      }
    }
    scrap[_pos].url = val;
    setData({ ...data, scrap: scrap });
  };

  const handleChange = (e) => {
    setData({ ...data, category: e.target.value });
  };

  // const scrpRayans = () => {
  //   setLoading({ ...loading, ryanscomputers: true });
  //   axios
  //     .post(DJANGO_SERVER_SCRAP, {
  //       type: "ryanscomputers",
  //       url: data.scrap[3].url,
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setScrappedData({ ...scrappedData, ryanscomputers: res.data.scrapped });
  //       setLoading({ ...loading, ryanscomputers: false });
  //     })
  //     .catch((e) => {
  //       alert("Something wrong.. Try again later!");
  //       setLoading({ ...loading, ryanscomputers: false });
  //     });
  // };

  const scrpGsmarena = () => {
    setLoading((d) => {
      return { ...d, gsmarena: true };
    });
    axios
      .post(DJANGO_SERVER_SCRAP, {
        type: GSMARENA,
        url: data.scrap[0].url,
      })
      .then((res) => {
        console.log(res.data);
        setScrappedData((d) => {
          return { ...d, gsmarena: res.data };
        });
        setLoading((d) => {
          return { ...d, gsmarena: false };
        });
      })
      .catch((e) => {
        alert('Something wrong.. Try again later!');
        setLoading((d) => {
          return { ...d, gsmarena: false };
        });
      });
  };
  // const scrpGsmarenaBD = () => {
  //   setLoading((d) => {
  //     return { ...d, gsmarenabd: true };
  //   });
  //   axios
  //     .post(DJANGO_SERVER_SCRAP, {
  //       type: GSMARENABD,
  //       url: data.scrap[1].url,
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setScrappedData((d) => {
  //         return { ...d, gsmarenabd: res.data };
  //       });
  //       setLoading((d) => {
  //         return { ...d, gsmarenabd: false };
  //       });
  //     })
  //     .catch((e) => {
  //       alert('Something wrong.. Try again later!');
  //       setLoading((d) => {
  //         return { ...d, gsmarenabd: false };
  //       });
  //     });
  // };
  const scrpMobiledokan = () => {
    setLoading((d) => {
      return { ...d, mobiledokan: true };
    });
    axios
      .post(DJANGO_SERVER_SCRAP, {
        type: MOBILEDOKAN,
        url: data.scrap[2].url,
      })
      .then((res) => {
        console.log(res.data);
        // console.log(res.data.image);
        setScrappedData((d) => {
          return { ...d, mobiledokan: res.data };
        });
        setLoading((d) => {
          return { ...d, mobiledokan: false };
        });
      })
      .catch((e) => {
        alert('Something wrong.. Try again later!');
        setLoading((d) => {
          return { ...d, mobiledokan: false };
        });
      });
  };
  const scrpMobiledokanco = () => {
    setLoading((d) => {
      return { ...d, mobiledokanco: true };
    });
    axios
      .post(DJANGO_SERVER_SCRAP, {
        type: MOBILEDOKANCO,
        url: data.scrap[3].url,
      })
      .then((res) => {
        console.log(res.data);
        setScrappedData((d) => {
          return { ...d, mobiledokanco: res.data };
        });
        setLoading((d) => {
          return { ...d, mobiledokanco: false };
        });
      })
      .catch((e) => {
        alert('Something wrong.. Try again later!');
        setLoading((d) => {
          return { ...d, mobiledokanco: false };
        });
      });
  };

  const formatData = (imgs) => {
    console.log('format data:', scrappedData);
    if (scrappedData.gsmarena) {
      let _data = {
        ...scrappedData.gsmarena,
        title: scrappedData.gsmarena.title + ' price in Bangladesh',
        metaTitle: scrappedData.gsmarena.title + ' price in Bangladesh & Full Specs',
        name: scrappedData.gsmarena.title,
        keyword: scrappedData.gsmarena.title,
        url: scrappedData.gsmarena.title
          .trim()
          .toLowerCase()
          .replaceAll(' ', '-'),

        images: imgs,
      };

      // setting all filters from gsmarena except price
      _data.filters = gsmarenaFilter(scrappedData.gsmarena);
      _data.model = _data.filters.model;
      _data.model_f = _data.filters.model.toLowerCase();

      _data.proscons = [{ pros: '', cons: '' }];
      if (scrappedData.mobiledokan) {
        if (scrappedData.mobiledokan.highlights) {
          let arr = [
            // this first item should be added on ui
            // {
            //   pros: 'Pros',
            //   cons: 'Cons',
            // },
          ];
          let pros = scrappedData.mobiledokan.highlights.pros;
          let cons = scrappedData.mobiledokan.highlights.cons;
          let count = pros.length > cons.length ? pros.length : cons.length;

          for (let i = 0; i < count; i++) {
            let obj = {
              pros: '',
              cons: '',
            };
            if (pros.length > i) {
              obj.pros = pros[i];
            }
            if (cons.length > i) {
              obj.cons = cons[i];
            }
            arr.push(obj);
          }

          _data.proscons = arr;
        }
      }
      // if (scrappedData.gsmarenabd) {
      //   if (scrappedData.gsmarenabd.desc)
      //     _data.desc = scrappedData.gsmarenabd.desc;
      //   // if (scrappedData.gsmarenabd.bd_price)
      //   //   _data.price = scrappedData.gsmarenabd.bd_price
      //   //     .replace("TK", "")
      //   //     .trim()
      //   //     .replace(",", "");
      //   _data.disclaimer = scrappedData.gsmarenabd.disclaimer;
      // }

      if (scrappedData.mobiledokanco) {
        _data.desc = scrappedData.mobiledokanco.desc;
        _data.price = scrappedData.mobiledokanco.price;
        if (scrappedData.mobiledokanco.category)
          _data.category = scrappedData.mobiledokanco.category;
        _data.category_path = scrappedData.mobiledokanco.category
          .trim()
          .toLowerCase()
          .replace(' ', '-');

        // setting only price here for filters
        _data.filters = mobiledokancoFilter(
          scrappedData.mobiledokanco,
          _data.filters
        );
        if (scrappedData.mobiledokanco.info) {
          _data.info = scrappedData.mobiledokanco.info;

          // adding field value
          for (let i = 0; i < scrappedData.mobiledokanco.info.length; i++) {
            if (scrappedData.mobiledokanco.info[i].name === 'Variant:') {
              _data.variant = 'UNKNOWN';
              if (scrappedData.mobiledokanco.info[i].value) {
                if (
                  scrappedData.mobiledokanco.info[i].value
                    .toLowerCase()
                    .includes('unknown')
                ) {
                  _data.variant = 'UNKNOWN';
                } else {
                  _data.variant = scrappedData.mobiledokanco.info[i].value;
                }
              }
            }
            if (scrappedData.mobiledokanco.info[i].name === 'Brand:')
              _data.brand = scrappedData.mobiledokanco.info[i].value;
          }
        }
        if (scrappedData.mobiledokanco.features) {
          // _data.features = scrappedData.mobiledokanco.features;

          // adding field value
          for (let i = 0; i < scrappedData.mobiledokanco.features.length; i++) {
            if (scrappedData.mobiledokanco.features[i].name === 'Released')
              _data.released = scrappedData.mobiledokanco.features[i].value;
            if (scrappedData.mobiledokanco.features[i].name === 'OS')
              _data.os = scrappedData.mobiledokanco.features[i].value;
            if (scrappedData.mobiledokanco.features[i].name === 'Display')
              _data.display = scrappedData.mobiledokanco.features[i].value;
            if (scrappedData.mobiledokanco.features[i].name === 'Camera')
              _data.camera = scrappedData.mobiledokanco.features[i].value;
            if (scrappedData.mobiledokanco.features[i].name === 'RAM')
              _data.ram = scrappedData.mobiledokanco.features[i].value;
            if (scrappedData.mobiledokanco.features[i].name === 'Battery')
              _data.battery = scrappedData.mobiledokanco.features[i].value;
          }
        }
      }

      _data.disclaimer =
        'Disclaimer: We do not guarantee that the information of this page is 100% accurate and up to date.';
      setFormatedData(_data);
    } else {
      setFormatedData(null);
      // {
      //       category: "Phones",
      //   specs: [
      //     {
      //       name: "",
      //       values: [{ name: "", values: "" }],
      //     },
      //   ],
      // }
    }
  };
  const downloadImg = (url) => {
    setLoading((d) => {
      return { ...d, imgDownloading: true };
    });
    axios
      .post(`${CDN_URL}/api/download`, {
        data: {
          image: url,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          setScrappedData((d) => {
            return { ...d, images: res.data.data, imgDownloaded: true };
          });
          formatData(res.data.data);
        }
        setLoading((d) => {
          return { ...d, imgDownloading: false };
        });
        setShowInput(!showInput);
      })
      .catch((e) => {
        alert('Something wrong: ' + JSON.stringify(e));
        setLoading((d) => {
          return { ...d, imgDownloading: false };
        });
        formatData(null);
        setShowInput(!showInput);
      });
  };
  return (
    <ReadContainer>
      <InputHeading title="Input Link" />

      <div className="link-input-div">
        {/* <PostCatgDropdown val={data.category} cb={handleChange} /> 
        <br />
          <br />
          <img className="link-upload-site-img" src={ryans} />
          <InputLabel label="ryanscomputers" />
          <TextArea
            id="title"
            label="title"
            rows="1"
            onChange={(e) => {
              changeUrl(e.target.value, "ryanscomputers");
            }}
            value={data.scrap[3].url}
          />
          <Button
            variant="contained"
            onClick={scrpRayans}
            disableElevation
            disabled={loading.ryanscomputers}
            size="small"
          >
            {loading.ryanscomputers ? (
              <CircularProgress
                sx={{ padding: "2px" }}
                color="inherit"
                size={18}
              />
            ) : (
              "Scrap"
            )}
          </Button> 
        <br />*/}
        <img className="link-upload-site-img" src={gsmarena} />
        <InputLabel
          label="gsmarena"
          tooltip="Required field to show scrapped data"
        />

        <TextArea
          id="title"
          label="title"
          rows="1"
          onChange={(e) => {
            changeUrl(e.target.value, GSMARENA);
          }}
          value={data.scrap[0].url}
        />

        {scrappedData.gsmarena ? (
          <Button
            variant="contained"
            disableElevation
            disabled={true}
            size="small"
          >
            <CheckCircleIcon color="success" />
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={scrpGsmarena}
            disableElevation
            disabled={loading.gsmarena}
            size="small"
          >
            {loading.gsmarena ? (
              <CircularProgress
                sx={{ padding: '2px' }}
                color="inherit"
                size={18}
              />
            ) : (
              'Scrap'
            )}
          </Button>
        )}
        {/* 
        <br />
        <br />
        <img className="link-upload-site-img" src={gsmarenabd} />
        <InputLabel label="gsmarenabd" />
        <TextArea
          id="url"
          label="url"
          rows="1"
          onChange={(e) => {
            changeUrl(e.target.value, GSMARENABD);
          }}
          value={data.scrap[1].url}
        />
        {scrappedData.gsmarenabd ? (
          <Button
            variant="contained"
            disableElevation
            disabled={true}
            size="small"
          >
            <CheckCircleIcon color="success" />
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={scrpGsmarenaBD}
            disableElevation
            disabled={loading.gsmarenabd}
            size="small"
          >
            {loading.gsmarenabd ? (
              <CircularProgress
                sx={{ padding: '2px' }}
                color="inherit"
                size={18}
              />
            ) : (
              'Scrap'
            )}
          </Button>
        )} */}

        <br />
        <br />
        <img className="link-upload-site-img" src={mobiledokan} />
        <InputLabel label="mobiledokan" />
        {/* make it dropdown */}
        <TextArea
          label="brand"
          rows="1"
          onChange={(e) => {
            changeUrl(e.target.value, MOBILEDOKAN);
          }}
          value={data.scrap[2].url}
        />
        <br />
        <br />
        {scrappedData.mobiledokan ? (
          <Button
            variant="contained"
            disableElevation
            disabled={true}
            size="small"
          >
            <CheckCircleIcon color="success" />
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={scrpMobiledokan}
            disableElevation
            disabled={loading.mobiledokan}
            size="small"
          >
            {loading.mobiledokan ? (
              <CircularProgress
                sx={{ padding: '2px' }}
                color="inherit"
                size={18}
              />
            ) : (
              'Scrap'
            )}
          </Button>
        )}

        <br />
        <br />

        <img className="link-upload-site-img" src={mobiledokanco} />
        <InputLabel label="mobiledokanco" />
        {/* make it dropdown */}
        <TextArea
          label="brand"
          rows="1"
          onChange={(e) => {
            changeUrl(e.target.value, MOBILEDOKANCO);
          }}
          value={data.scrap[3].url}
        />
        <br />
        {scrappedData.mobiledokanco ? (
          <Button
            variant="contained"
            disableElevation
            disabled={true}
            size="small"
          >
            <CheckCircleIcon color="success" />
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={scrpMobiledokanco}
            disableElevation
            disabled={loading.mobiledokanco}
            size="small"
          >
            {loading.mobiledokanco ? (
              <CircularProgress
                sx={{ padding: '2px' }}
                color="inherit"
                size={18}
              />
            ) : (
              'Scrap'
            )}
          </Button>
        )}
      </div>

      <br />
      <br />
      <hr />

      <br />
      <div>
        <Button
          variant="contained"
          disabled={loading.imgDownloading}
          onClick={() => {
            // console.log(scrappedData);

            // TODO: format scrapped data
            // ...

            // download data if provide mobiledokan data
            // then go for data set
            // tillthen set a loading ui
            if (scrappedData.imgDownloaded || !scrappedData.mobiledokan) {
              formatData(scrappedData.images);
              setShowInput(!showInput);
            } else if (scrappedData.mobiledokan) {
              downloadImg(scrappedData.mobiledokan.image);
            }
          }}
          disableElevation
          size="small"
        >
          {showInput ? 'Hide Input' : 'Show Input'}
        </Button>
        <br />
        <br />
        {loading.imgDownloading ? (
          <CircularProgress sx={{ padding: '2px' }} color="inherit" size={24} />
        ) : (
          showInput && (
            <div style={{ backgroundColor: '#fff', padding: '12px' }}>
              {console.log(formatedData)}
              <PostInput
                edit={false}
                scrappedData={formatedData}
                toolbarHide={false}
              />
            </div>
          )
        )}
      </div>
      <br />
      <br />
      <br />
    </ReadContainer>
  );
}
