import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import NormalizeView from '../NormalizeView';
import { BASE_URL, BLOG_ROUTE, CLIENT_URL } from '../../config';
import AddHeader from '../AddHeader';
import SubmitLoading from '../SubmitLoading';
import { ReadContainer600 } from '../share';
import { getBlogApi, getBlogUrl } from '../../utils';

export default function () {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const handleDelete = (url) => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/api/blog/deleteId/${url}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          fetch(getBlogApi(perPage, pageNo));
        } else {
          alert('Something wrong try again later..');
        }
        setLoading(false);
      })
      .catch((err) => {
        alert('Unable to delete this blog.');
        console.log(err);
        setLoading(false);
      });
  };
  //--------------------------------------------------------------------
  // post pagination block start
  const [searchParams] = useSearchParams();
  const _pageNo = searchParams.get('pageNo');

  const [pageNo, setPageNo] = useState(_pageNo == null ? 0 : _pageNo);
  const perPage = 4;
  const [pageCount, setPageCount] = useState(1);

  const handleChange = (event, value) => {
    setPageNo(value);
  };

  const totalPages = (itemCount) => {
    let cout = Math.ceil(itemCount / perPage);
    setPageCount(cout);
  };

  const fetch = (url) => {
    axios
      .get(`${BASE_URL}/api/${url}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.success) setData(res.data.data);
        else setData([]);
        totalPages(res.data.totalCount ? res.data.totalCount : 0);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
        totalPages(0);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(getBlogApi(perPage, pageNo));
  }, [_pageNo]);
  // post pagination block end
  //--------------------------------------------------------------------

  return (
    <ReadContainer600>
      <AddHeader title="Blog" />
      <SubmitLoading open={loading} />
      <div>
        {data.map((val, index) => (
          <NormalizeView
            key={`item-${index}`}
            index={index}
            title={val.title}
            text={val.desc}
            onEdit={() => {
              navigate(`edit/${val._id}`);
            }}
            onDelete={() => {
              handleDelete(val._id);
            }}
            onGoto={() => {
              window.open(`${CLIENT_URL}/${BLOG_ROUTE}/${val.url}`, '_blank');
            }}
          />
        ))}
        {/* ----------------------pagination ui-------------------------- */}
        <div style={{ margin: '26px 0' }}>
          <Pagination
            renderItem={(item) => {
              return (
                <PaginationItem
                  component={Link}
                  to={`${getBlogUrl(perPage, item.page)}`}
                  {...item}
                />
              );
            }}
            count={pageCount}
            variant="outlined"
            shape="rounded"
            page={pageNo}
            onChange={handleChange}
          />
        </div>
        {/* ------------------------------------------------ */}
      </div>
    </ReadContainer600>
  );
}
