import React, { useEffect, useState } from 'react';
// import ImageResize from 'quill-image-resize-module';
import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');

import 'quill/dist/quill.snow.css'; // Add css for snow theme
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

// https://quilljs.com/docs/modules/toolbar/
const m2 = {
  theme: 'snow',
  modules: {
    imageResize: {
      displaySize: true,
    },
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['link', 'image'],

      ['clean'],
    ],
  },
};
export const quillImgModule = {
  // imageResize: {
  //   displaySize: true,
  // },
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['link', 'image'],

    ['clean'],
  ],
};
export const quillModuleMini = {
  // imageResize: {
  //   displaySize: true,
  // },
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['link', 'image'],

    ['clean'],
  ],
};
export const quillModule = {
  toolbar: {
    container: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ header: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ color: [] }, { background: [] }],
      ['link'],
    ],
  },
  clipboard: {
    matchVisual: false,
  },
};
export default ({ cb, delta }) => {
  const { quill, quillRef } = useQuill({ modules: quillModuleMini });
  const [firstTime, setFirstTime] = useState(true);
  // console.log(quill); // undefined > Quill Object
  // console.log(quillRef); // { current: undefined } > { current: Quill Editor Reference }
  useEffect(() => {
    if (quill) {
      // quill.register('modules/ImageResize', ImageResize);
      if (firstTime) {
        if (delta) quill.setContents(delta);
        setFirstTime(false);
      }
      cb(quill);
      // quill.on('text-change', (delta) => {
      //   // const text = quill.getText();
      //   // console.log(text); // not useful maybe only for search match
      //   // console.log('delta', delta); // not useful
      //   // console.log('innerHTML', quill.root.innerHTML); // important!
      //   // console.log('delta', quill.getContents()); // important!
      //   if (cb) {
      //     cb(quill.getContents(), quill.root.innerHTML, quill.getText());
      //   }
      // });
    }
  }, [quill]);
  return (
    <div style={{ width: '100%', backgroundColor: 'white' }}>
      <div ref={quillRef} />
    </div>
  );
};
