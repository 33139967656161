import styled from "styled-components";

export const ProfileCircle = styled.div`
  position: relative;
  border-radius: 50%;
  margin: 0 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  border: 2px solid lightgray;
  overflow: hidden;

  :hover {
    background-color: lightgrey;
    .update-photo {
      z-index: 2;
      display: block;
      justify-content: center;
      align-items: center;
    }
    p {
      z-index: 3;
      diaplay: block;
    }
  }

  :active {
    border: 2px solid lightblue;
  }

  :focus {
    border: 2px solid blue;
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    cursor: pointer;
  }

  p {
    position: absolute;
    display: none;
  }

  .update-photo {
    cursor: pointer;
    margin-top: -40px;
    position: absolute;
    display: none;
    height: 160px;
    width: 100%;
    color: lightgrey;
    background-color: #00000042;
  }
`;

export const Container = styled.div`
  display: flex;
  min-height: 900px;
  background-color: rgba(230, 230, 230, 0.3);
`;

export const Body = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;
