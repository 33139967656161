import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import axios from 'axios';
import InputHeading from '../InputHeading';
import { BASE_URL } from '../../config';
import InputLabel from '../InputLabel';
import useAuth from '../../contexts/AuthContext';
import { TextArea } from './components';
import './UploadProduct.css';
import { ReadContainer600 } from '../share';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { quillImgModule } from './RichEditor';

export default function ({ edit, handleClose }) {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const { quill, quillRef } = useQuill({ modules: quillImgModule });

  const [data, setData] = useState({
    title: '',
    desc: '',
    delta: '',
    html: '',
    embeded: '',
  });

  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState(true);
  const [editDelta, setEditDelta] = useState();

  useEffect(() => {
    if (edit) {
      axios
        .get(`${BASE_URL}/api/sections/${id}`)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data);
            setData(res.data.data);
            setEditDelta(res.data.data.delta);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [edit]);

  useEffect(() => {
    if (quill && editDelta)
      if (first) {
        quill.setContents(editDelta);
        setFirst(false);
      }
  }, [quill, editDelta]);

  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });

  const changeTitle = (event) => {
    setData({ ...data, title: event.currentTarget.value });
  };

  const submitPost = (e) => {
    e.preventDefault();

    if (currentUser && !currentUser.isAuth) {
      return alert('Please log in first.');
    }

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });
    const upload = {
      ...data,
      desc: quill.getText(),
      delta: quill.getContents(),
      html: quill.root.innerHTML,
    };
    console.log(upload);
    axios
      .post(
        edit
          ? `${BASE_URL}/api/sections/update`
          : `${BASE_URL}/api/sections/add`,
        upload
      )
      .then((res) => {
        if (res.data.success) {
          navigate('/section');
        } else {
          alert('Failed while uploading your post. Please try again later.');
        }
        setUploadState({ ...uploadStatus, productUploading: false });
        setLoading(false);
      })
      .catch((err) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        console.log(err);
        alert('Internal Server Error');
        setLoading(false);
      });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Add Section"
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/section"
      />
      <form onSubmit={submitPost} className="upload-moadal">
        <InputLabel label="Title" />
        <TextArea
          id="title"
          label="title"
          rows="1"
          onChange={changeTitle}
          value={data.title}
        />

        <div style={{ width: '100%', backgroundColor: 'white' }}>
          <div ref={quillRef} />
        </div>
        <p>or</p>
        <InputLabel label="Html Embeded Code" />
        <TextArea
          id="html"
          label="html"
          rows="8"
          onChange={(e) => {
            setData({ ...data, embeded: e.currentTarget.value });
          }}
          value={data.embeded}
        />
        <br />
        <br />
      </form>
    </ReadContainer600>
  );
}
