import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useAuth from '../contexts/AuthContext';
import { useNavigate } from 'react-router';
import { CenterdDiv } from './share';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function SignupView() {
  const { createAdmin, currentUser } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [adminPassword, setAdminPass] = React.useState('');
  const [data, setData] = React.useState('');

  const form = {
    name: '',
    username: '',
    email: '',
    password: '',
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    // setSubmitting(true);
    console.log({
      ...data,
      admin: { _id: currentUser.userId, password: adminPassword },
    });
    const res = await createAdmin({
      ...data,
      admin: { _id: currentUser.userId, password: adminPassword },
    });
    // setSubmitting(false);
    setOpen(false);
    if (res.data.success) {
      alert('New user created successfylly!');
      navigate('/');
    } else {
      alert('Mmm.. Something wrong..');
      console.log(res.data);
      // try {
      //   console.log(res.data);
      //   if (res.data.error.path)
      //     setFieldError(res.data.error.path, res.data.error.message);
      // } catch (e) {}
    }
  };

  const schema = yup.object({
    name: yup.string().required('Please enter a name.'),
    username: yup.string().required('Unique username.'),
    email: yup
      .string()
      .required('Please provide a valid email address')
      .email('Invalid email format. e.g example@gmail.com'),
    password: yup
      .string()
      .trim()
      .required("Your password can't start or end with a blank space")
      .min(6, 'Password should be at least 6 charecters!'),
  });

  const handleSubmitForm = async (values, { setSubmitting, setFieldError }) => {
    setOpen(true);
    setData(values);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Formik
        initialValues={form}
        validationSchema={schema}
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Typography
              sx={{
                marginTop: '1rem',
                marginBottom: '1.5rem',
                fontWeight: 'bold',
              }}
              component="h1"
              variant="h4"
            >
              Create an User!
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.name && touched.name ? errors.name : null}
              error={errors.name && touched.name}
            />

            <TextField
              sx={{ marginTop: '1.5rem' }}
              variant="outlined"
              size="small"
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.username && touched.username ? errors.username : null
              }
              error={errors.username && touched.username}
            />

            <TextField
              sx={{ marginTop: '1.5rem' }}
              variant="outlined"
              fullWidth
              size="small"
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.email && touched.email ? errors.email : null}
              error={errors.email && touched.email}
            />

            <TextField
              sx={{ marginTop: '1.5rem' }}
              variant="outlined"
              fullWidth
              size="small"
              id="password"
              label="Password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.password && touched.password ? errors.password : null
              }
              error={errors.password && touched.password}
            />

            <FormControl fullWidth>
              <Button
                disabled={isSubmitting}
                sx={{
                  marginTop: '1.5rem',
                  marginBottom: '1rem',
                  minWidth: '140px',
                  minHeight: '42px',
                }}
                variant="contained"
                color="primary"
                type="submit"
              >
                {isSubmitting ? 'Please wait..' : 'Create Account'}
              </Button>
            </FormControl>
          </form>
        )}
      </Formik>
      {/* <CenterdDiv>
        <Button
          sx={{
            marginTop: '1.5rem',
            marginBottom: '1rem',
            minWidth: '140px',
            minHeight: '42px',
          }}
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          Create Account
        </Button>
      </CenterdDiv> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Save Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are going to create a new admin who will have all the access.
            Enter your password to save the changes.
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 1 }} variant="standard">
            <InputLabel htmlFor="admin-password">Password</InputLabel>
            <Input
              fullWidth
              id="admin-password"
              type={showPassword ? 'text' : 'password'}
              value={adminPassword}
              onChange={(e) => setAdminPass(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save Changes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
