import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { ReadContainer600 } from '../share';
import SearchPost from '../SearchPost';
import ItemChoose from './ItemChoose';
import { SortableItem, SortableContainer, arrayMove } from './Reorder';
import { CDN_URL, BASE_URL } from '../../config';

export default function () {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  const fetch = () => {
    axios.get(`${BASE_URL}/api/topposts`).then((res) => {
      if (res.data.success) {
        setSelected(res.data.data);
      } else {
        alert('Something wrong try again later..');
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/api/topposts/delete/${id}`)
      .then((res) => {
        if (res.data.success) {
          fetch();
        } else {
          alert('Something wrong try again later..');
          setLoading(false);
        }
      })
      .catch((err) => {
        alert('Unable to delete this item.');
        console.log(err);
        setLoading(false);
      });
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const update = arrayMove(selected, oldIndex, newIndex);
    const map = update.map((val, i) => ({ _id: val._id, position: i }));
    axios
      .post('/api/topposts/position', map)
      .then((res) => {})
      .catch(({ response }) => {});
    setSelected(update);
  };
  const addToLatest = (m) => {
    axios
      .post(`${BASE_URL}/api/topposts/add`, {
        postId: m._id,
        url: m.url,
        name: m.name,
        title: m.title,
        price: m.price,
        image: m.media[0].thumbnail,
        position: selected.length,
      })
      .then((res) => {
        console.log(res.data);
        setSelected((oldArray) => [
          ...oldArray,
          { ...m, image: m.media[0].thumbnail },
        ]);
      })
      .catch(({ response }) => {});
  };
  return (
    <ReadContainer600>
      <h2>Top Rated Phones</h2>
      <hr />
      <SearchPost
        cb={(posts) => {
          setPosts(posts);
        }}
      />
      <br />
      {posts?.map((val) => {
        return (
          <ItemChoose
            data={val}
            cb={() => {
              addToLatest(val);
            }}
          />
        );
      })}
      <br />
      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
        {selected.map((val, index) => {
          val.title = val.name;
          val.desc = val.price;
          val.imagePath = `${CDN_URL}/cdn/small/${val.image}`;
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={val}
              onDelete={() => {
                setSelected(selected.filter((item) => item._id != val._id));
                handleDelete(val._id);
              }}
            />
          );
        })}
      </SortableContainer>
    </ReadContainer600>
  );
}
