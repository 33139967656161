import React from 'react';
import styled from 'styled-components';
import { CDN_URL } from '../../config';

const Container = styled.div`
  transition: all 0.2s;
  border: 1px solid transparent;
  padding: 5px;
  display: flex;
  align-items: center;
  margin: 8px 0;

  img {
    height: 48px;
    width: 48px;
    margin-right: 12px;
  }
  border: 1px solid lightgrey;
  :hover {
    /* border-radius: 3px; */
    /* box-shadow: 0 0 3px rgb(35 173 255); */
    /* -webkit-box-shadow: 0 0 3px rgba(35 173 255); */
    /* transform: scale(1.01); */
    border: 1px solid #1991FF;
  }
  :active {
    border: 1px solid rgb(35 173 255);
  }
  :focus {
    border: 1px solid rgb(35 173 255);
  }
  cursor: pointer;
`;
const TextContainer = styled.div`
  h4 {
    margin: 0;
    padding: 0;
  }
  p{
    margin-top: 2px;
  }
`;
export default function ItemChoose({ data, cb }) {
  return (
    <Container onClick={cb}>
      <img src={`${CDN_URL}/cdn/small/${data.media[0].thumbnail}`} />
      <TextContainer>
        <h4>{data.title}</h4>
        <p>{data.url}</p>
      </TextContainer>
    </Container>
  );
}
