import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import AddHeader from '../AddHeader';
import SubmitLoading from '../SubmitLoading';
import { ReadContainer600 } from '../share';
import { SortableItem, SortableContainer, arrayMove } from './SocialReorder';
import { CDN_URL, BASE_URL } from '../../config';

export default function () {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetch = () => {
    axios
      .get(`${BASE_URL}/api/social`)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
        } else {
          alert('Something wrong try again later..');
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/api/social/delete/${id}`)
      .then((res) => {
        if (res.data.success) {
          fetch();
        } else {
          alert('Something wrong try again later..');
        }
        setLoading(false);
      })
      .catch((err) => {
        alert('Unable to delete this link.');
        console.log(err);
        setLoading(false);
      });;
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const update = arrayMove(data, oldIndex, newIndex);
    const map = update.map((val, i) => ({ _id: val._id, position: i }));
    axios
      .post(`${BASE_URL}/api/social/position`, map)
      .then((res) => {})
      .catch(({ response }) => {});
    setData(update);
  };

  const addToLatest = (m) => {
    axios
      .post(`${BASE_URL}/api/social/add`, {
        postId: m._id,
        url: m.url,
        name: m.name,
        title: m.title,
        price: m.price,
        image: m.media[0].thumbnail,
        position: data.length,
      })
      .then((res) => {
        console.log(res.data);
        setData((oldArray) => [
          ...oldArray,
          { ...m, image: m.media[0].thumbnail },
        ]);
      })
      .catch(({ response }) => {});
  };
  return (
    <ReadContainer600>
      <AddHeader title="Social Links" />
      <SubmitLoading open={loading} />

      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
        {data.map((val, index) => {
          val.title = val.platform;
          val.desc = val.link;
          val.imagePath = `${CDN_URL}/cdn/social/${val.logo}`;
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={val}
              shorten={true}
              onDelete={() => {
                setData(data.filter((item) => item._id != val._id));
                handleDelete(val._id);
              }}
            />
          );
        })}
      </SortableContainer>
    </ReadContainer600>
  );
}
