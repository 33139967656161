import React from 'react';
import styled from 'styled-components';
import './IndexView.css';

import Dashboard from '../components/rd/Dashboard';

export const Body = styled.div`
  display: flex;
  padding: 0 26px;
  flex-grow: 1;
  flex-direction: column;
`;

export default function IndexView() {
  return <Dashboard />;
}
