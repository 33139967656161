import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import IndexView from './views/IndexView.js';
import LoginView from './views/LoginView.js';
import PageNotFound from './views/PageNotFound.js';
import useAuth from './contexts/AuthContext.js';
import Settings from './views/Settings.js';
import FilterOptions from './views/FilterOptions.js';

import SideBarContainer from './views/SideBarContainer.js';

import AllBlog from './components/rd/AllBlog.js';
import AllPage from './components/rd/AllPage.js';
import AllBrand from './components/rd/AllBrand.js';
import AllCategory from './components/rd/AllCategory.js';
import AllComparison from './components/rd/AllComparison.js';
import AllSideSection from './components/rd/AllSideSection.js';
import AllSocialLink from './components/rd/AllSocialLink.js';
import AllReview from './components/rd/AllReview.js';
import BlogInput from './components/upload/BlogInput.js';
import PageInput from './components/upload/PageInput.js';
import BrandInput from './components/upload/BrandInput.js';
import CategoryInput from './components/upload/CategoryInput.js';
import ComparisonInput from './components/upload/ComparisonInput.js';
import SectionInput from './components/upload/SectionInput.js';
import SocialLinkInput from './components/upload/SocialLinkInput.js';

import ProfileEditForm from './components/ProfileEditForm.js';

import LinkInput from './components/upload/LinkInput.js';
import PostInput from './components/upload/PostInput.js';
import AllProduct from './components/rd/AllProduct.js';
import AllLink from './components/rd/AllLink.js';
import AllPost from './components/rd/AllPost.js';
import QuillTesting from './views/QuillTesting.js';

export default function App() {
  const { currentUser } = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/quill" element={<QuillTesting />}></Route>
        <Route
          exact
          path="/"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<IndexView />} />
        </Route>
        <Route
          exact
          path="/dashboard"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<IndexView />} />
        </Route>
        <Route
          exact
          path="/posts"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<IndexView />} />
        </Route>
        <Route
          exact
          path="/blog"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<AllBlog />} />
          <Route path="add" element={<BlogInput />} />
          <Route path="edit/:id" element={<BlogInput edit />} />
        </Route>
        <Route
          exact
          path="/review"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<AllReview />} />
        </Route>
        <Route
          exact
          path="/page"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<AllPage />} />
          <Route path="add" element={<PageInput />} />
          <Route path="edit/:id" element={<PageInput edit />} />
        </Route>
        <Route
          exact
          path="/brand"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<AllBrand />} />
          <Route path="add" element={<BrandInput />} />
          <Route path="edit/:id" element={<BrandInput edit />} />
        </Route>
        <Route
          exact
          path="/category"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<AllCategory />} />
          <Route path="add" element={<CategoryInput />} />
          <Route path="edit/:id" element={<CategoryInput edit />} />
        </Route>
        <Route
          exact
          path="/comparisons"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<AllComparison />} />
          <Route path="add" element={<ComparisonInput />} />
          <Route path="edit/:id" element={<ComparisonInput edit />} />
        </Route>
        <Route
          exact
          path="/section"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<AllSideSection />} />
          <Route path="add" element={<SectionInput />} />
          <Route path="edit/:id" element={<SectionInput edit />} />
        </Route>
        <Route
          exact
          path="/link"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<LinkInput />} />
          <Route path="add" element={<LinkInput />} />
          <Route path="edit/:id" element={<LinkInput edit />} />
        </Route>
        <Route
          exact
          path="/social"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<AllSocialLink />} />
          <Route path="add" element={<SocialLinkInput />} />
          <Route path="edit/:id" element={<SocialLinkInput edit />} />
        </Route>
        <Route
          exact
          path="/post"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<AllPost />} />
          <Route path="add" element={<PostInput />} />
          <Route path="url/:id" element={<PostInput edit url />} />
          <Route path="edit/:id" element={<PostInput edit />} />
        </Route>

        <Route
          path="/login"
          element={currentUser.isAuth ? <Navigate to="/" /> : <LoginView />}
        />
        <Route
          exact
          path="/settings"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<Settings />} />
        </Route>
        <Route
          exact
          path="/filter"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<FilterOptions />} />
        </Route>
        <Route
          exact
          path="/profile"
          element={
            currentUser.isAuth ? <SideBarContainer /> : <Navigate to="/login" />
          }
        >
          <Route path="" element={<ProfileEditForm />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
