import { BASE_URL } from './config';
export const NUMBER_REGX = /^[0-9\b]+$/;
export const MoneyFormatInt = (_int) => {
  let formatter = new Intl.NumberFormat();
  return formatter.format(_int);
};

function get_nth_suffix(number) {
  switch (number) {
    case 1:
    case 21:
    case 31:
      return number + 'st';
    case 2:
    case 22:
      return number + 'nd';
    case 3:
    case 23:
      return number + 'rd';
    default:
      return number + 'th';
  }
}
const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const date = (timestamp) => {
  const formatThis = new Date(timestamp);

  return (
    formatThis.getDate() +
    '-' +
    month[formatThis.getMonth()] +
    '-' +
    formatThis.getFullYear()
  );
};

// same for both url & api
export const getFilterUrl = (
  searchTxt,
  brandId,
  perPage,
  pageNo,
  sort,
  sortTime
) =>
  `posts?search=${searchTxt}&brand=${brandId}&pageNo=${pageNo}&perPage=${perPage}&sort=${sort}&sortTime=${sortTime}`;

export const getRecentPostApi = (perPage, pageNo) =>
  `posts/recent?pageNo=${pageNo}&perPage=${perPage}`;

export const getRecentPostUrl = (perPage, pageNo) =>
  `post?pageNo=${pageNo}&perPage=${perPage}`;

export const getRecentPostUrlDash = (perPage, pageNo) =>
  `posts?pageNo=${pageNo}&perPage=${perPage}`;

export const getBlogUrl = (perPage, pageNo) =>
  `?pageNo=${pageNo}&perPage=${perPage}`;

export const getBlogApi = (perPage, pageNo) =>
  `blog?pageNo=${pageNo}&perPage=${perPage}`;

export const getReviewUrl = (perPage, pageNo) =>
  `?pageNo=${pageNo}&perPage=${perPage}`;

export const getReviewApi = (perPage = 10, pageNo = 1) =>
  `reviews?pageNo=${pageNo}&perPage=${perPage}`;
