import React from 'react'
import Button from '@mui/material/Button';

export default function AddBtn({cb}) {
  return (
    <Button variant="contained" onClick={cb} disableElevation size="small">
      Add
    </Button>
  )
}
