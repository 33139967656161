import styled from 'styled-components';

export const CenterdDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const RowDiv = styled.div`
  display: flex;
  width: 100%;
`;
export const RowDivAlignCenter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const RowDivAlignCenterBetween = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const HoriSpace6px = styled.div`
  width: 6px;
`;

export const VirSpace6px = styled.div`
  height: 6px;
`;
export const VirSpace3px = styled.div`
  height: 2px;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const WidthContent = styled.div`
  max-width: 975px;
  width: 100%;
`;
export const ContainerBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
export const ReadContainer = styled.div`
  max-width: 800px;
`;
export const ReadContainer600 = styled.div`
  max-width: 600px;
`;
export const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
`;
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;
export const Body = styled.div`
  display: flex;
  width: 100%;
  padding: 0 26px;
  flex-grow: 1;
  flex-direction: column;

  @media screen and (max-width: 892px) {
    padding: 0 12px;
  }
`;

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 12,
  padding: '15px',
};
