import { Button } from "@mui/material";
import styled from "styled-components";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CC from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";

import React from "react";
import { borderRadius } from "@mui/system";

const Container = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
`;
const Center = styled.div`
  position: absolute;
  left: 3px;
  top: 3px;
  text-align: center;
`;
const ContainerBox = styled.div`
  flex-grow: 1;
  width: 0;
  padding: 0 20px 0 0;
  p {
    margin: 0;
    margin-top: 6px;
    padding: 0;
    font-size: 12px;
    color: darkgrey;
  }
  a {
    font-size: 12px;

    /* display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    text-decoration: none;
    :hover {
      color: #551a8b;
    }
    color: grey;
    /* color: #222; */
  }
`;

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          p: 1,
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          p: 1,
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={20}
        p={8}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default function NormalizeView({ scrap, onDelete }) {
  const getStatus = (status) => {
    if (status === "UPLOADING")
      // return <FacebookCircularProgress />;
      return (
        <Box sx={{ p: 1.3 }} style={{ cursor: "pointer" }}>
          {" "}
          <CC size={22} />{" "}
        </Box>
      );

    if (status === "FAILED")
      return (
        <IconButton color="error">
          <ReplayIcon />
        </IconButton>
      );

    if (status === "SUCCESS")
      // return <div />;
      //<IconButton aria-label="delete" color="success">
      return (
        <IconButton>
          <CheckCircleIcon />
        </IconButton>
      );
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "8px",
        margin: "0 0 12px",
        position: "relative",
        border: ".5px solid lightgrey",
        overflow: "hidden",
        width: "100%",
        minHeight: "80px",
      }}
    >
      <Container>
        {scrap &&
          scrap.map((val) => (
            <ContainerBox>
              <div style={{ position: "relative", display: "flex" }}>
                <img
                  src={val.logo}
                  style={{
                    height: "48px",
                    width: "48px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                />
                <Center>{getStatus(val.uploadStatus)}</Center>
              </div>
              <div style={{ padding: "6px 0 0" }}>
                {/* <p
                style={{
                  fontSize: "12px",
                  color: "grey",
                }}
              >
                {val.siteName}
              </p> */}
                <a target="_blank" rel="noreferrer" href={val.url}>
                  {val.url}
                </a>
                {/* <p className="dark-text">{val.uploadStatus}</p> */}
              </div>
            </ContainerBox>
          ))}
      </Container>
      {/* <div
        style={{
          height: "12px",
        }}
      /> */}
      <div
        style={{
          position: "absolute",
          right: 2,
          top: 12,
          padding: "0 8px 4px",
        }}
        // style={{
        //   display: "flex",
        //   position: "absolute",
        //   right: 0,
        //   justifyContent: "space-between",
        //   padding: "0 8px 4px",
        // }}
      >
        {/* <div /> */}
        {/* <IconButton aria-label="delete" color="error" size="small">
          <DeleteIcon fontSize="small" />
        </IconButton> */}
        <Button
          variant="outlined"
          size="small"
          color="error"
          style={{ fontSize: "12px" }}
          onClick={onDelete}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}
