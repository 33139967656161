import React from 'react';
import NormalizeView from '../NormalizeFooterItem';
import ReorderRoundedIcon from '@mui/icons-material/ReorderRounded';
import IconButton from '@mui/material/IconButton';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import './SortableItem.css';

// Drag Btn
export const DragHandle = sortableHandle(() => (
  <IconButton className="dragHandler">
    <ReorderRoundedIcon />
  </IconButton>
));

// MY CARDS
export const SortableItem = sortableElement(
  ({ value, onDelete, onEdit, shorten }) => (
    <div className="dragElement">
      <NormalizeView
        title={value.title}
        text={value.desc}
        onDelete={onDelete}
        shorten={shorten}
      />
      <DragHandle />
    </div>
  )
);

// Drag Container
export const SortableContainer = sortableContainer(({ children }) => {
  return <div className="dragContainer">{children}</div>;
});

export const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export const arrayMove = (array, from, to) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};
