import { Button } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import { TextArea } from './upload/components';
import { HoriSpace6px, RowDiv, RowDivAlignCenter } from './share';
import { useState } from 'react';

const Wrapper = styled.div`
  padding: 8px;
  width: 100%;
  background-color: white;
  margin-bottom: 8px;
  border: 1px solid transparent;
  cursor: pointer;

  :hover {
    background-color: #f8f8f8;
    border: 1px solid lightblue;
  }
`;

const Container = styled.div`
  h3 {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
  p {
    margin: 0;
    margin-top: 6px;
    padding: 0;
    line-height: 1.4;
  }
`;

export default function NormalizeReview({
  title,
  image,
  text,
  reply,
  onDelete,
  onReply,
}) {
  const [isReply, showReply] = useState(false);
  const [input, setInput] = useState(() => {
    return reply && reply.text ? reply.text : '';
  });
  const [replyTxt, setReplyTxt] = useState(() => {
    return reply && reply.text ? reply.text : '';
  });
  let str = text;
  if (str?.length > 240) {
    if (typeof str === 'string' || str instanceof String) {
      str = str.substring(0, 220) + '...';
    }
  }
  return (
    <Wrapper>
      <RowDivAlignCenter>
        {image && (
          <div style={{ display: 'flex' }}>
            <img style={{ width: '50px', height: '50px' }} src={image} />
            <HoriSpace6px />
          </div>
        )}
        <Container>
          <h3 className="dark-text">{title}</h3>
          <p className="dark-text">{str}</p>
        </Container>
      </RowDivAlignCenter>
      <div
        style={{
          height: '12px',
        }}
      />
      <RowDiv>
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={onDelete}
        >
          Delete
        </Button>
        <HoriSpace6px />

        <Button
          variant="outlined"
          size="small"
          onClick={() => showReply(!isReply)}
        >
          Reply
        </Button>
      </RowDiv>
      <br />
      {replyTxt && !isReply && (
        <div
          style={{
            backgroundColor: '#f2f2f2',
            borderRadius: '4px',
            padding: '10px',
            marginBottom: '14px',
          }}
        >
          <p>{replyTxt}</p>
        </div>
      )}
      {isReply && (
        <div>
          <TextArea
            id="reply"
            label="reply"
            placeholder="admin reply.."
            rows="3"
            onChange={(e) => {
              setInput(e.currentTarget.value);
            }}
            value={input}
          />
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              onReply(input);
              setReplyTxt(input);
              showReply(!isReply);
            }}
          >
            Post
          </Button>
        </div>
      )}
    </Wrapper>
  );
}
