import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "../InputLabel";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Dropzone from "react-dropzone";
import { TextArea } from "./components";
import useAuth from "../../contexts/AuthContext";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import "./UploadProduct.css";
import { BASE_URL } from "../../config";
import InputHeading from "../InputHeading";
import { Button } from "@mui/material";
import { ReadContainer } from "../share";


export const RemoveItemBtn = styled.div`
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.32);

  border-radius: 50%;
  padding: 2px;
  svg {
    color: lightgrey;
    font-size: 16px;
  }
  :hover {
    svg {
      color: red;
    }
    border: 0.5px solid red;
  }
  cursor: pointer;
`;
export const UploadedItem = styled.div`
  position: relative;
  height: 120px;
  width: 130px;
  /* margin-top: 52px; */
  display: flex;
  overflow: hidden;
  border: 0.5px dashed lightgray;
  align-items: center;
  justify-content: center;
`;

export default function () {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState({
    author: "",
    title: "",
    website: "",
    desc: "",
    shortDesc: "",
    date: "",
    technologies: "",
  });

  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const submit = (e) => {
    e.preventDefault();
    if (currentUser && !currentUser.isAuth) {
      return alert("Please log in first.");
    }
    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });

    let upData;
    if (link) {
      upData = {
        ...data,
        media: [
          {
            type: "link",
            link: data.link,
          },
        ],
        technologies: data.technologies.split(",").map((val) => val.trim()),
      };
    } else {
      upData = {
        ...data,
        media: uploadedFiles,
        technologies: data.technologies.split(",").map((val) => val.trim()),
      };
    }
    axios
      .post(`${BASE_URL}/api/design-portfolio/add`, upData)
      .then((res) => {
        if (res.data.success) {
          alert("Uploaded successfully!");
          navigate("/admin/design-portfolio");
        } else {
          alert("Failed while uploading your post. Please try again later.");
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        alert(
          response.err == undefined ? "Internal Server Error" : response.err
        );
        setLoading(false);
      });
  };

  const onDrop = (files) => {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) formData.append("file", files[i]);

    const config = {
      header: { "content-type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadState({
          ...uploadStatus,
          uploadPercentage: percentCompleted,
          uploadCompleted: false,
          isUploading: true,
        });
      },
    };
    axios
      .post(`${BASE_URL}/api/design-portfolio/uploadfiles`, formData, config)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data);
          setUploadedFiles((files) => [...files, ...res.data.media]);
          setUploadState({
            ...uploadStatus,
            uploadPercentage: 0,
            uploadCompleted: true,
            isUploading: false,
          });
        } else {
          alert("failed to save the files");
        }
      });
  };

  return (
    <ReadContainer>
      <InputHeading
        title="Add a Design Portfolio"
        btnTxt="Save"
        submit={submit}
        loading={loading}
        back="/admin/design-portfolio"
      />
      <form onSubmit={submit} className="upload-moadal">
        {!link && (
          <div className="grid-layout">
            {uploadedFiles.map((val, index) => (
              <UploadedItem key={val.path}>
                {console.log("displayName:", val.originalname)}
                {val.type === "video" ? (
                  <video
                    key={val.path}
                    autoPlay={true}
                    loop={true}
                    muted
                    style={{ width: "100%" }}
                  >
                    <source src={`${BASE_URL}/${val.path}`} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    key={val.path}
                    style={{ width: "100%" }}
                    alt="thumbnail"
                    src={`${BASE_URL}/${val.path}`}
                  />
                )}
                <RemoveItemBtn
                  onClick={() => {
                    axios
                      .post(`${BASE_URL}/api/design-portfolio/deletefile`, {
                        path: val.path,
                      })
                      .then((res) => {
                        console.log(res.data);
                        if (res.data.success) {
                          setUploadedFiles(
                            uploadedFiles.filter((_, i) => i !== index)
                          );
                        } else alert(res.data);
                      });
                  }}
                >
                  <CloseIcon />
                </RemoveItemBtn>
              </UploadedItem>
            ))}
            <div>
              <InputLabel label="Upload" />
              <Dropzone
                className="video-drop-zone"
                onDrop={onDrop}
                multiple={true}
                maxSize={800000000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="hover-lightgrey"
                    style={{
                      width: "130px",
                      height: "120px",
                      display: "flex",
                      border: ".5px dashed lightgray",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />

                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {uploadStatus.isUploading && (
                        <CircularProgress
                          disableShrink
                          size="24px"
                          style={{ color: "lightgrey" }}
                        />
                      )}
                      {uploadStatus.isUploading && (
                        <p style={{ color: "grey", fontSize: "12px" }}>
                          {`${uploadStatus.uploadPercentage}%`}
                        </p>
                      )}

                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            flexDirection: "row",
                            fontWeight: "bold",
                            color: "rgb(99, 91, 255)",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <ArrowUpwardIcon
                            style={{ fontSize: "16px", marginRight: "2px" }}
                          />
                          <p style={{ margin: "0" }}>Upload</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        )}
        {!link && (
          <Button
            variant="contained"
            onClick={() => {
              setLink(true);
            }}
            style={{ marginTop: "12px" }}
            disableElevation
          >
            Or Add a Embedded Video Link
          </Button>
        )}
        {link && (
          <>
            <InputLabel label="Video Link" />
            <TextArea
              id="link"
              label="link"
              placeholder="https://.."
              rows="1"
              onChange={(event) => {
                setData({ ...data, link: event.currentTarget.value });
              }}
              value={data.link}
            />
          </>
        )}

        <div>
          {/* <InputLabel label="Author" />
          <TextArea
            id="author"
            label="author"
            rows="1"
            onChange={(event) => {
              setData({ ...data, author: event.currentTarget.value });
            }}
            value={data.author}
          /> */}
          <InputLabel label="Date" />
          <TextArea
            id="date"
            label="date"
            rows="1"
            onChange={(event) => {
              setData({ ...data, date: event.currentTarget.value });
            }}
            value={data.date}
          />
          <InputLabel label="Headline" />
          <TextArea
            id="title"
            label="title"
            rows="1"
            onChange={(event) => {
              setData({ ...data, title: event.currentTarget.value });
            }}
            value={data.title}
          />
          <InputLabel label="Short description" />
          <TextArea
            id="shortDesc"
            label="shortDesc"
            rows="4"
            onChange={(event) => {
              setData({ ...data, shortDesc: event.currentTarget.value });
            }}
            value={data.shortDesc}
          />
          <InputLabel label="Full description" />
          <TextArea
            id="desc"
            label="desc"
            rows="10"
            onChange={(event) => {
              setData({ ...data, desc: event.currentTarget.value });
            }}
            value={data.desc}
          />
          <InputLabel label="Site link" />
          <TextArea
            id="link"
            label="link"
            rows="1"
            onChange={(event) => {
              setData({ ...data, website: event.currentTarget.value });
            }}
            value={data.website}
          />
        </div>
        <br />
        <br />
        <br />
        <h4 className="input-section-desc">Technology Used</h4>
        <InputLabel
          label="Key points"
          tooltip="Use comma(,) to separate individual technology"
        />
        <TextArea
          id="technologies"
          label="technologies"
          rows="1"
          onChange={(event) => {
            setData({ ...data, technologies: event.currentTarget.value });
          }}
          value={data.technologies}
        />
        <br />
        <br />
        <br />
      </form>
    </ReadContainer>
  );
}
