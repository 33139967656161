import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import useAuth from '../../contexts/AuthContext';
import { useNavigate } from 'react-router';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SingleLvlMobileNav from './SingleLvlMobileNav';

import { STATIC_AVETER } from '../../constants';
import { CDN_URL } from '../../config';
import { navs } from '../sidebar/Sidebar';
import {
  ProfileCircleNav,
  Avatar,
  NavSection,
  NavBar,
} from './NavbarComponents';
import './Navbar.css';
export default function ProfileView() {
  const { logout, currentUser } = useAuth();
  const [state, setState] = useState(false);
  const navigate = useNavigate();

  const handleSideBarClick = (index, redirect) => {
    navigate(redirect);
    setState(false);
  };
  const toggleDrawer = (toggle) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(toggle);
  };
  return (
    <>
      <NavBar className="navbar-laptop">
        <NavSection style={{ '--layout': 'start' }}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div
              style={{ display: 'flex', color: 'grey', alignItems: 'center' }}
            >
              <h2
                style={{
                  marginLeft: '4px',
                  fontWeight: '600',
                  fontSize: '1.2rem',
                  color: '#f2f2f2',
                }}
              >
                Mobileinfo Admin
              </h2>
            </div>
          </Link>
        </NavSection>
        <NavSection style={{ '--layout': 'end' }}>
          <div
            style={{ display: 'flex', alignItems: 'center', color: 'white' }}
          >
            {currentUser.userId && (
              <div>
                <div className="desktop-logout">
                  <Button onClick={logout} color="error" variant="outlined">
                    Log out
                  </Button>
                </div>
                <div className="mobile-logout">
                  <p onClick={logout}>Log out</p>
                </div>
              </div>
            )}
            {currentUser.userId ? (
              <Link
                to={`/profile`}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <ProfileCircleNav className="rounded-img">
                  <img
                    src={
                      currentUser.user.image
                        ? `${CDN_URL}/cdn/photo/${currentUser.user.image}`
                        : STATIC_AVETER
                    }
                    alt="image"
                  />
                </ProfileCircleNav>
              </Link>
            ) : (
              <Link to="/login">
                <Avatar>
                  <AccountCircleRoundedIcon />
                </Avatar>
              </Link>
            )}
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </NavSection>
      </NavBar>
      {state && (
        <SingleLvlMobileNav
          d={navs}
          callback={handleSideBarClick}
          o={state}
          close={toggleDrawer(false)}
        />
      )}
    </>
  );
}
