import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/navbar/Navbar";
import { Container, Body, Root } from "../components/share";
import { Outlet } from "react-router";
import "./style.css";
export default function ({ children }) {
  return (
    <Root>
      <Navbar />
      <Container>
        <Sidebar />
        <Body className="body-container">
          <br />
          {children}
          <Outlet />
          <br />
        </Body>
      </Container>
    </Root>
  );
}
