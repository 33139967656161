import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import SubmitLoading from "./SubmitLoading";
export default function InputHeading({ title, btnTxt, submit, back, loading }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {back && (
          <IconButton
            onClick={() => navigate(back)}
            disableElevation
            color="primary"
            style={{ textTransformation: "none", marginRight: "10px" }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <h2 className="dark-text">{title}</h2>
      </div>
      <div>
        {btnTxt && (
          <Button
            variant="contained"
            onClick={submit}
            disableElevation
            size="small"
          >
            {btnTxt}
          </Button>
        )}
      </div>
      <SubmitLoading open={loading} />
    </div>
  );
}
