import React from 'react';
import Button from '@mui/material/Button';

export default function ProsConsInput({ srcData, onChange }) {
  const addField = () => {
    affiliateValuesChanged(srcData ? srcData.length : 0, {
      affiliateId: '',
      productId: '',
      link: '',
      price: '',
      vendorImg: '',
      vendorName: '',
    });
  };

  const changeFieldValue = (index, obj) => {
    affiliateValuesChanged(index, obj);
  };

  const affiliateValuesChanged = (position, obj) => {
    let affiliate = srcData;
    if (affiliate) {
      if (affiliate.length <= position) affiliate.push(obj);
      else affiliate[position] = obj;
    } else {
      affiliate = [];
      affiliate.push(obj);
    }

    onChange(affiliate);
  };

  return (
    <div>
      {srcData &&
        srcData.map((val, index) => {
          return (
            <div>
              <h4>Vendor {index + 1}</h4>
              <input
                placeholder="affiliate Id"
                style={{
                  padding: '4px 6px',
                  width: '100%',
                  marginBottom: '4px',
                  textAlign: 'start',
                  fontSize: '1rem',
                  boxSizing: 'border-box',
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                }}
                onChange={(e) => {
                  changeFieldValue(index, {
                    ...val,
                    affiliateId: e.target.value,
                  });
                }}
                value={val.affiliateId}
              />
              <input
                placeholder="product Id"
                style={{
                  padding: '4px 6px',
                  marginBottom: '4px',
                  width: '100%',
                  textAlign: 'start',
                  fontSize: '1rem',
                  boxSizing: 'border-box',
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                }}
                onChange={(e) => {
                  changeFieldValue(index, {
                    ...val,
                    productId: e.target.value,
                  });
                }}
                value={val.productId}
              />
              <input
                placeholder="link"
                style={{
                  padding: '4px 6px',
                  marginBottom: '4px',
                  width: '100%',
                  textAlign: 'start',
                  fontSize: '1rem',
                  boxSizing: 'border-box',
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                }}
                onChange={(e) => {
                  changeFieldValue(index, {
                    ...val,
                    link: e.target.value,
                  });
                }}
                value={val.link}
              />
              <input
                placeholder="price"
                style={{
                  padding: '4px 6px',
                  marginBottom: '4px',
                  width: '100%',
                  textAlign: 'start',
                  fontSize: '1rem',
                  boxSizing: 'border-box',
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                }}
                onChange={(e) => {
                  changeFieldValue(index, {
                    ...val,
                    price: e.target.value,
                  });
                }}
                value={val.price}
              />
              <input
                placeholder="vendor name"
                style={{
                  padding: '4px 6px',
                  marginBottom: '4px',
                  width: '100%',
                  textAlign: 'start',
                  fontSize: '1rem',
                  boxSizing: 'border-box',
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                }}
                onChange={(e) => {
                  changeFieldValue(index, {
                    ...val,
                    vendorName: e.target.value,
                  });
                }}
                value={val.vendorName}
              />
              <input
                placeholder="vendor image"
                style={{
                  padding: '4px 6px',
                  marginBottom: '8px',
                  width: '100%',
                  textAlign: 'start',
                  fontSize: '1rem',
                  boxSizing: 'border-box',
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                }}
                onChange={(e) => {
                  changeFieldValue(index, {
                    ...val,
                    vendorImg: e.target.value,
                  });
                }}
                value={val.vendorImg}
              />
              <hr />
            </div>
          );
        })}

      <Button
        variant="outlined"
        disableElevation
        size="small"
        onClick={addField}
      >
        Add
      </Button>
    </div>
  );
}
