import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import axios from 'axios';
import InputHeading from '../InputHeading';
import InputLabel from '../InputLabel';
import useAuth from '../../contexts/AuthContext';
import { TextArea } from './components';
import { BASE_URL } from '../../config';
import { ReadContainer600 } from '../share';
import AdvancedEditor from './AdvancedEditor';
import './UploadProduct.css';

export default function ({ edit }) {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [htmlContent, setHtmlContent] = useState(''); // empty is important here to print desc after api response
  const inputRef = useRef(null);
  const [data, setData] = useState({
    title: '',
    metaDesc: '',
    metaTitle: '',
    desc: '',
    imageName: '',
    delta: '',
    html: '',
    sections: [],
  });
  const [uploadStatus, setUploadState] = useState({
    isUploading: false,
    uploadPercentage: 0,
    fileUploading: false,
    productUploading: false,
    progressbar: false,
  });
  const [imgUpload, setUploadedImg] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      axios
        .get(`${BASE_URL}/api/page/byId/${id}`)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data);
            setData(res.data.data);
            setHtmlContent(res.data.data.html || ' ');
            setUploadedImg(res.data.data.image);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [edit]);

  const changeTitle = (event) => {
    setData({ ...data, title: event.currentTarget.value });
  };
  const changeKeyword = (event) => {
    setData({ ...data, keyword: event.currentTarget.value });
  };

  const submitPost = (e) => {
    e.preventDefault();

    if (currentUser && !currentUser.isAuth) {
      return alert('Please log in first.');
    }

    setLoading(true);
    setUploadState({ ...uploadStatus, productUploading: true });
    axios
      .post(edit ? `${BASE_URL}/api/page/update` : `${BASE_URL}/api/page/add`, {
        ...data,
        desc: inputRef.current?.editor.getText(),
        html: inputRef.current?.value,
        image: imgUpload,
      })
      .then((res) => {
        if (res.data.success) {
          // alert("Uploaded successfully!");
          navigate('/page');
        } else {
          alert('Failed while uploading your post. Please try again later.');
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        setUploadState({ ...uploadStatus, productUploading: false });
        alert(
          response.err == undefined ? 'Internal Server Error' : response.err
        );
        setLoading(false);
      });
  };

  return (
    <ReadContainer600>
      <InputHeading
        title="Add Page"
        btnTxt="Publish"
        submit={submitPost}
        loading={loading}
        back="/page"
      />
      <form onSubmit={submitPost} className="upload-moadal">
        <InputLabel label="Title" />
        <TextArea
          id="title"
          placeholder="title"
          rows="1"
          onChange={changeTitle}
          value={data.title}
        />
        <InputLabel label="http://domain.com/page-url" />
        <TextArea
          id="url"
          placeholder="page-url"
          rows="1"
          onChange={(e) => {
            setData({ ...data, url: e.currentTarget.value });
          }}
          value={data.url}
        />
        {edit && htmlContent && (
          <AdvancedEditor value={htmlContent} ref={inputRef} />
        )}
        {!edit && <AdvancedEditor value={htmlContent} ref={inputRef} />}
        <br />
        <InputLabel
          label="Key words"
          tooltip="Use comma(,) to separate each keywords."
        />
        <TextArea
          id="keyword"
          placeholder="keywords"
          rows="1"
          onChange={changeKeyword}
          value={data.keyword}
        />
        <br />
        <InputLabel label="Meta description" />
        <textarea
          id="metaTitle"
          label="metaTitle"
          placeholder="Meta title"
          style={{
            padding: '4px 6px',
            width: '100%',
            textAlign: 'start',
            boxSizing: 'border-box',
            fontSize: '14px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          }}
          rows="3"
          onChange={(e) => {
            setData({ ...data, metaTitle: e.currentTarget.value });
          }}
          value={data.metaTitle}
        />
        <br />
        <InputLabel label="Meta description" />
        <textarea
          id="metaDesc"
          label="metaDesc"
          placeholder="Within 160-300 char"
          style={{
            padding: '4px 6px',
            width: '100%',
            textAlign: 'start',
            boxSizing: 'border-box',
            fontSize: '14px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          }}
          rows="3"
          onChange={(e) => {
            setData({ ...data, metaDesc: e.currentTarget.value });
          }}
          value={data.metaDesc}
        />
        <br />
        <br />
        <br />
      </form>
    </ReadContainer600>
  );
}
