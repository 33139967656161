import React from 'react';
import InputLabel from '../InputLabel';
import Button from '@mui/material/Button';

export default function ProsConsInput({ val: data, onChange }) {
  const addField = () => {
    prosconsValuesChanged(data ? data.length : 0, { pros: '', cons: '' });
  };

  const changeFieldValue = (index, pros, cons) => {
    prosconsValuesChanged(index, { pros: pros, cons: cons });
  };

  const prosconsValuesChanged = (val_index, o) => {
    const proscons = data;
    if (proscons.length <= val_index) proscons.push(o);
    else proscons[val_index] = o;

    onChange(proscons);
  };

  return (
    <div>
      <InputLabel label="Pros vs Cons" />
      {data &&
        data.map((data, pos) => {
          return (
            <div className="spec-input-value-div-child">
              <textarea
                placeholder="Pros"
                style={{
                  margin: '0 6px 0 0',
                  padding: '4px 6px',
                  width: '140px',
                  textAlign: 'start',
                  fontSize: '.875rem',
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                }}
                rows="3"
                onChange={(e) => {
                  changeFieldValue(pos, e.target.value, data.cons);
                }}
                value={data.pros}
              />
              <textarea
                id="value"
                placeholder="Cons"
                className="spec-input-value-div-val"
                rows="3"
                onChange={(e) => {
                  changeFieldValue(pos, data.pros, e.target.value);
                }}
                value={data.cons}
              />
            </div>
          );
        })}

      <Button
        variant="outlined"
        disableElevation
        size="small"
        onClick={addField}
      >
        Add
      </Button>
    </div>
  );
}
