import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
export default function InputHeading({ title, btnTxt, url }) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <h2 className="dark-text">{title}</h2>
      <hr />
      <div>
        <Button
          size="small"
          variant="contained"
          onClick={() => navigate(url ? url : "add")}
          disableElevation
          style={{ textTransformation: "none" }}
        >
          {btnTxt ? btnTxt : "Add New"}
        </Button>
      </div>
    </div>
  );
}
