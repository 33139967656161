import styled from "styled-components";

export const SideBarItemText = styled.p`
  display: inline;
  color: lightgrey;
  margin: 0 0 0 12px;
  font-size: 0.875rem;
  cursor: pointer;
`;

export const SideBarItemSelectedText = styled.p`
  display: inline;
  color: white;
  margin: 0 0 0 12px;
  font-size: 0.875rem;
  cursor: pointer;
`;

export const ProfileCircle = styled.div`
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  object-fit: cover;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    cursor: pointer;
  }
`;
export const ProfileCircleUser = styled.div`
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 54px;
  object-fit: cover;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const TabItemText = styled.p`
  font-size: 12px;
  padding-top: 4px;
  margin: 0;
  white-space: nowrap;
`;

export const TabItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const SideBarItem = styled.a`
  display: flex;
  align-items: center;
  color: lightgrey;
  padding: 8px 20px;
  border-left: 4px solid transparent;
  cursor: pointer;
  :hover{
    background-color: #ffffff1A;
    border-left: 4px solid #E14D43;
  }
`;
export const TabSideBar = styled.div`
  display: scroll;
  width: 82px;
  background-color: white;
`;
export const SideBarTabItemSelected = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  padding: 20px;
  cursor: pointer;
  background-color: #dbdbdb;
`;

export const SideBarTabItem = styled.a`
  display: flex;
  align-items: center;

  color: #606060;
  justify-content: center;
  padding: 20px;
  :hover {
    background-color: #ececec;
  }
`;

export const SideBarItemSelected = styled.a`
  display: flex;
  align-items: center;
  color: white;
  padding: 8px 20px;
  background-color: #E14D43;
  border-left: 4px solid white;
  cursor: pointer;
`;

export const SideBar = styled.div`
  min-width: 220px;
  background-color: #1a2228;
  background-color: #3c4e5b;
  height: 100vh;
  position: sticky;
  top:0;
`;
