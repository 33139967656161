import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import NormalizeView from '../NormalizeView';
import AddHeader from '../AddHeader';
import SubmitLoading from '../SubmitLoading';
import { ReadContainer, ReadContainer600 } from '../share';
import { BASE_URL } from '../../config';
import TopRatedPhone from './TopRatedPhone';
import AllBrand from './AllBrand';

export default function () {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetch = () => {
    axios
      .get(`${BASE_URL}/api/sections`)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
        } else {
          alert('Something wrong try again later..');
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    axios.post(`${BASE_URL}/api/sections/delete/${id}`).then((res) => {
      if (res.data.success) {
        fetch();
      } else {
        alert('Something wrong try again later..');
        setLoading(false);
      }
    }).catch((err) => {
      alert('Unable to delete this section.');
      console.log(err);
      setLoading(false);
    });;
  };

  return (
    <ReadContainer600>
      <h1>Sidebar</h1>
      <SubmitLoading open={loading} />
      <TopRatedPhone />
      <br />
      <br />
      <hr/>
      <AddHeader title="Custom Sections" />
      <div>
        {data.map((val, index) => (
          <NormalizeView
            key={`item-${index}`}
            index={index}
            title={val.title}
            text={val.desc}
            onEdit={() => {
              navigate(`edit/${val._id}`);
            }}
            onDelete={() => {
              handleDelete(val._id);
            }}
          />
        ))}
      </div>
    </ReadContainer600>
  );
}
