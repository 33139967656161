import React, { useEffect, useState } from 'react';
import useAuth from '../../contexts/AuthContext.js';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import NormalizeReview from '../NormalizeReview.js';
import { BASE_URL } from '../../config.js';
import SubmitLoading from '../SubmitLoading.js';
import { ReadContainer600 } from '../share.js';
import { getReviewApi, getReviewUrl } from '../../utils.js';


export default function () {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { currentUser } = useAuth();
  //--------------------------------------------------------------------
  // post pagination block start
  const [searchParams] = useSearchParams();
  const _pageNo = searchParams.get('pageNo');
  const _perPage = searchParams.get('perPage');

  const [pageNo, setPageNo] = useState(_pageNo == null ? 0 : _pageNo);
  const [perPage, setResultPerPage] = useState(_perPage == null ? 5 : _perPage);
  const [pageCount, setPageCount] = useState(1);

  const handleChange = (event, value) => {
    setPageNo(value);
  };

  const totalPages = (itemCount) => {
    let cout = Math.ceil(itemCount / perPage);
    setPageCount(cout);
  };

  const fetch = (url) => {
    axios
      .get(`${BASE_URL}/api/${url}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.success) setData(res.data.data);
        else setData([]);
        totalPages(res.data.totalCount ? res.data.totalCount : 0);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
        totalPages(0);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(
      getReviewApi(
        _perPage == null ? 5 : _perPage,
        _pageNo == null ? 0 : _pageNo
      )
    );
  }, [_pageNo, _perPage]);
  // post pagination block end
  //--------------------------------------------------------------------

  // const fetch = () => {
  //   axios
  //     .get(`${BASE_URL}/api/reviews`)
  //     .then((res) => {
  //       if (res.data.success) {
  //         setData(res.data.data);
  //       } else {
  //         alert('Something wrong try again later..');
  //       }
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   fetch();
  // }, []);

  const handleDelete = (val) => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/api/reviews/delete`, {
        id: val._id,
        userId: val.userId,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          fetch(getReviewApi(perPage, pageNo));
        } else {
          alert('Something wrong try again later..');
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleReply = (reply, reveiw) => {
    if (!currentUser.user) return alert('Please login!');
    setLoading(true);

    if (!currentUser.user.name || !currentUser.user.image) {
      setLoading(false);
      return alert('Please setup your admin name and image.');
    }

    if (!reply) {
      setLoading(false);
      return alert('Please write your reply first.');
    }

    axios
      .post(`${BASE_URL}/api/reviews/reply/${reveiw._id}`, {
        reply: {
          admin: currentUser.userId,
          name: currentUser.user.name,
          image: currentUser.user.image,
          username: currentUser.user.username,
          text: reply,
          timestamp: Date.now(),
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          fetch();
        } else {
          alert('Something wrong try again!');
        }
        setLoading(false);
      })
      .catch((err) => {
        alert('Something wrong try again!');
        setLoading(false);
      });
  };

  return (
    <ReadContainer600>
      <h2 className="dark-text">User Reviews</h2>
      <hr />
      <SubmitLoading open={loading} />
      <div>
        {data.map((val, index) => (
          <NormalizeReview
            key={`item-${index}`}
            index={index}
            title={val.userName}
            text={val.text}
            reply={val.reply}
            onReply={(v) => handleReply(v, val)}
            onDelete={() => {
              handleDelete(val);
            }}
          />
        ))}
        {/* ----------------------pagination ui-------------------------- */}
        <div style={{ margin: '26px 0' }}>
          <Pagination
            renderItem={(item) => {
              return (
                <PaginationItem
                  component={Link}
                  // Bug:
                  // item next page has some bug
                  // it's just add 1 after the count for the first time page refreshed
                  // with pagination url

                  to={`${getReviewUrl(perPage, item.page)}`}
                  {...item}
                />
              );
            }}
            count={pageCount}
            variant="outlined"
            shape="rounded"
            page={pageNo}
            onChange={handleChange}
          />
        </div>
        {/* ------------------------------------------------ */}
      </div>
    </ReadContainer600>
  );
}
