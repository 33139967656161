export const YOUR_DOMAIN = 'https://www.0mobiles.com';

// prod
export const CLIENT_URL = 'https://www.0mobiles.com';
export const BASE_URL = "https://server.0mobiles.com";
export const CDN_URL = "https://cdn.0mobiles.com";
export const DJANGO_SERVER_SCRAP = 'https://django.0mobiles.com/api/scrap/';

// dev
// export const CLIENT_URL = 'http://localhost:3000';
// export const BASE_URL = 'http://localhost:5000';
// export const CDN_URL = 'http://localhost:5001';
// export const DJANGO_SERVER_SCRAP = 'http://127.0.0.1:8000/api/scrap/';

export const DOMAIN = 'https://www.0mobiles.com';
export const GSMARENA = 'gsmarena';
export const GSMARENABD = 'gsmarenabd';
export const MOBILEDOKAN = 'mobiledokan';
export const MOBILEDOKANCO = 'mobiledokanco';
export const LOGO_PLACEHOLDER = 'bucket/images/logo.png';
export const IMAGE_PLACEHOLDER = 'bucket/images/image.jpg';

export const BLOG_ROUTE = 'reviews';